$normal: "Merriweather", Georgia, sans-serif;
$m: "Montserrat", Georgia, sans-serif;
$orange: #ff911d;
$gradient: linear-gradient(to right, rgb(255,248,233) 0%, rgb(255,147,5) 100%);

$decktop: 1170px;
$big: 1024px;
$medium: 960px;
$medium-s: 768px;
$small: 600px;
$x-small: 400px;