.about-bg {
	background-image: url(/assets/images/ep_bg.jpg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: top;
}
.about {
	display: grid;
	grid-template-columns: 400px 1fr;
	grid-column-gap: 85px;
	grid-row-gap: 110px;
	padding-top: 30px;
	padding-bottom: 130px;

	@media screen and (max-width: 1280px) {
		grid-column-gap: 50px;
		grid-row-gap: 80px;
	}
	@media screen and (max-width: 980px) {
		grid-row-gap: 30px;
		padding-top: 20px;
		padding-bottom: 90px;
	}
	@media screen and (max-width: 900px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 30px;
	}
	@media screen and  (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and  (max-width: 720px) {
		grid-template-columns: 1fr;
		padding-top: 0;
	}
	@media screen and  (max-width: 480px) {
		grid-row-gap: 15px;
	}
	p {
		padding: 12px 0;
	}
	.about__subtitle {
		padding: 20px 0;
		text-transform: uppercase;
		font-size: 25px;
	}
	&__photo {
		@media screen and  (max-width: 720px) {
			margin: 0 auto;
		}
	}
	&__block {
		padding: 10px;
		padding-left: 160px; 
		background-image: url(/assets/images/ep_2.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left bottom;

		@media screen and (max-width: 1280px) {
			padding-left: 60px; 
		}
		@media screen and  (max-width: 768px) {
			.about__subtitle {
				padding: 0;
			}
		}
		@media screen and  (max-width: 720px) {
			order: -1;
			padding: 0;
			background-image: none;
		}

		.about__title {
			text-align: left;

			span {
				display: block;
				margin-left: 20px;
			}
		}
		p {
			max-width: 750px;
		}
	}
	&__text {
		@media screen and (max-width: 900px) {
			grid-column: 1/-1;
		}
	}
	.check-list {
		margin: 45px 0 0;
		padding: 0;

		@media screen and (max-width: 900px) {
		    margin: 0;
		}

		li {
			margin-bottom: 30px;
		}
	}
}
.about-form {
	max-width: 1040px;
	margin: 0 auto 85px;
	padding: 40px;
	background-image: url(/assets/images/ep_3.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 90px 0 0 0;

	@media screen and  (max-width: 1199px) {
		margin-bottom: 50px;
	}

	@media screen and  (max-width: 768px) {
		margin-bottom: 30px;
		padding: 20px;
	}

	&__title {
		padding: 45px 0 40px;

		@media screen and  (max-width: 768px) {
			padding: 15px 0 25px;
		}
	}
	&__grid {
		max-width: 750px;
		margin: 0 auto;
	}
}
.about-price {
	padding-bottom: 80px;

	@media screen and (max-width: 980px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}

	h2 {
		font-size: 25px;
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 50px;
		margin: 0;
		padding: 10px 15px 0;
		counter-reset: myCounter;

		@media screen and (max-width: 1199px) {
			grid-gap: 30px;
		}
		@media screen and (max-width: 900px) {
			grid-template-columns: 1fr;
		}
		@media screen and (max-width: 400px) {
			padding: 0;
		}

		&-item {
			position: relative;
			display: flex;
			align-items: center;
			min-height: 203px; 
			padding-left: 265px;
    		list-style: none;

    		@media screen and (max-width: 1199px) {
				padding-left: 220px;
			}
			@media screen and (max-width: 600px) {
				min-height: 150px;
				padding-left: 170px;
			}
			@media screen and (max-width: 400px) {
				min-height: 0;
				padding-top: 160px;
				padding-left: 0;
			}

    		&::before {
			    position: absolute;
			    top: 0;
			    left: 0;
			    z-index: 2;
			    counter-increment: myCounter;
			    content: counter(myCounter);
			   	width: 203px;
  				height: 203px; 
  				line-height: 201px;
  				font-size: 55px;
  				font-weight: 300;
  				color: $orange;
  				text-align: center;
			    border-style: solid;
  				border-width: 1px;
  				border-color: $orange;
  				border-radius: 50%;

  				@media screen and (max-width: 600px) {
					width: 150px;
	  				height: 150px; 
	  				line-height: 148px;
	  				font-size: 50px;
				}
				@media screen and (max-width: 400px) {
					left: 50%;
					transform: translateX(-50%);
				}
			}
			&::after {
			    position: absolute;
			    top: -20px;
			    left: -25px;
			    z-index: 1;
			    content: '';
			   	width: 250px;
  				height: 215px; 
  				background-repeat: no-repeat;

  				@media screen and (max-width: 600px) {
					width: 150px;
	  				height: 150px; 
	  				background-size: contain;
				}
				@media screen and (max-width: 400px) {
					left: 50%;
					transform: translateX(-50%);
				}
			}
			&:nth-child(1)::after {
				background-position: left top;
				background-image: url(/assets/images/ep_decor_1.png);
			}
			&:nth-child(2)::after {
				background-position: left bottom;
				background-image: url(/assets/images/ep_decor_2.png);
			}
			&:nth-child(3)::after {
				background-position: left bottom;
				background-image: url(/assets/images/ep_decor_3.png);
			}
			&:nth-child(4)::after {
				top: 0;
				background-position: center;
				background-image: url(/assets/images/ep_decor_4.png);
			}
			&:nth-child(5)::after {
				top: 20px;
				background-position: center;
				background-image: url(/assets/images/ep_decor_5.png);
			}
			@media screen and (min-width: 901px) {
				p {
					max-width: 335px;
				}
			}
		}
	}
}
.about-sert {
	padding-bottom: 65px;
	margin-bottom: 60px;

	&--team-item {
		padding-bottom: 45px;
	}
	
	@media screen and (max-width: 980px) {
		padding-bottom: 50px;
		margin-bottom: 20px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
		margin-bottom: 0px;
	}

	.main-video-list {
		position: relative;
		margin: 0;
		padding: 0 60px;

		@media screen and (max-width: 768px) {
			padding: 0;
		}
		.swiper-slide {
			display: flex;
    		flex-direction: column;
			height: auto;
		}
		.slick-slide {
			display: flex;
    		flex-direction: column;
			flex-grow: 1;
			padding: 10px 25px 20px; 
			cursor: pointer;
		}
	}
	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		line-height: 0;
		box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.25);

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 21%, rgba(255,255,255,1) 100%);
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			background-image: url(/assets/images/zoom.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 40px;
			opacity: 0;
			transition: 0.2s;
		}
		&:hover::after {
			opacity: 1;
		}
	}
}
.about_video {
	width: 100%;
	max-width: 1040px;
	margin: 0 auto 80px;
	
	iframe {
		display: block;
		border: 0px;
	}
	@media screen and (max-width: 992px) {
		margin-bottom: 40px;
	}
}