.webinar-item__text .org-ab_list ul {
    display: block;
    column-count: 2;
    column-gap: 20px;
    padding-top: 5px;
    padding-bottom: 30px;
}
.webinar-item__text .org-ab_list ul li {
    padding-left: 40px;
    max-width: 600px;
    margin-bottom: 20px;
}
.webinar-item__text .org-ab_list ul li::before,
.webinar-item__text .org-ab_profit ul li::before {
    position: absolute;
    left: 0;
    top: 0;
}
.webinar-item__text .org-ab_for ol {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0 0;
    counter-reset: org;
    list-style: none;
}
.webinar-item__text .org-ab_for ol li {
    counter-increment: org;
    width: 47%;
}
.webinar-item__text .org-ab_for ol li::before {
    content: counter(org)'.';
    display: inline-block;
    margin-right: 2px;
}
.org-ab_about {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 10px;
    margin-bottom: 25px;
}
.org-ab_about__img {
    width: 40%;
}
.org-ab_about__text {
    width: 57%;
}
.webinar-item__text .org-ab_about .org-ab_about__text p:first-child,
.webinar-item__text .org-ab_about .org-ab_about__text p {
    padding: 0;
    margin: 20px 0 0;
}
.webinar-item__text .org-ab_programm p {
    padding-top: 0;
}
.webinar-item__text .org-ab_demos {
    padding-top: 25px;
}
.webinar-item__text .org-ab_demos ul {
    display: grid;
    grid-template-columns: repeat(4, 23.5%);
    justify-content: space-between;
    row-gap: 35px;
    font-size: 13px;
}
.webinar-item__text .org-ab_demos ul li iframe {
    display: block;
    margin: 20px 0;
}
.webinar-item__text .org-ab_demos ul li::before {
    top: 2px;
}
.webinar-item__text .org-ab_methods p {
    padding: 0 0 15px;
}
.webinar-item__text .org-ab_profit {
    padding-bottom: 20px;
}
.webinar-item__text .org-ab_profit ul {
    column-count: 2;
    column-gap: 20px;
    margin: 5px 0 20px;
}
.webinar-item__text .org-ab_profit ul li {
    max-width: 480px;
    padding-left: 40px;
    margin-bottom: 20px;
}
.webinar-item__text .org-ab_profit ul::after {
    content: "";
    display: block;
    height: 20px;
}
.org-ab_author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px;
}
.org-ab_author__img {
    width: 48.5%;
}
.org-ab_author__text {
    width: 48.5%;
    max-width: 600px;
}
.webinar-item__text .org-ab_author__text p {
    padding-top: 6px;
}
.webinar-item__text .org-ab_author__text p:nth-child(1),
.webinar-item__text .org-ab_author__text p:nth-child(2) {
    font-size: 25px;
}
@media screen and (max-width: 992px) {
    .org-ab_author {
        align-items: flex-start;
    }
    .webinar-item__text .org-ab_author__text p:nth-child(1),
    .webinar-item__text .org-ab_author__text p:nth-child(2) {
        font-size: 20px;
    }
    .webinar-item__text .org-ab_demos ul {
        grid-template-columns: repeat(3, 31.5%);
        row-gap: 25px;
    }
}
@media screen and (max-width: 768px) {
    .webinar-item__text .org-ab_list ul,
    .webinar-item__text .org-ab_profit ul {
        column-count: initial;
    }
    .webinar-item__text .org-ab_for ol li {
        width: 100%;
        margin-bottom: 20px;
    }
    .org-ab_about__img,
    .org-ab_about__text,
    .org-ab_author__img,
    .org-ab_author__text {
        width: 100%;
    }
    .org-ab_about__img {
        text-align: center;
    }
    .webinar-item__text .org-ab_profit ul::after {
        display: none;
    }
    .webinar-item__text .org-ab_demos ul {
        grid-template-columns: repeat(2, 48.5%);
        row-gap: 20px;
    }
}
@media screen and (max-width: 420px) {
    .webinar-item__text .org-ab_demos ul {
        grid-template-columns: 100%;
    }
}