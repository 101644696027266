.team-page {
	background-image: url(/assets/images/team_bg.jpg);
	background-repeat: no-repeat;
	background-position: top right;
	background-size: auto;

	@media screen and (max-width: 1480px) {
	    background-size: 50% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.team {
	padding-bottom: 120px;

	@media (max-width: 1199px) {
		padding-bottom: 80px;
	}
	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	&__text {
		position: relative;
		max-width: 1270px;
		margin: 0 auto 85px;
		padding: 15px 0 15px 100px;

		@media (max-width: 1199px) {
			margin-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
			padding-left: 30px;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 13.5px;
			height: 100%;
			background-image: linear-gradient(to bottom, rgb(255,248,233) 0%, rgb(255,147,5) 100%);

		}

		p {
			padding: 12px 0;
		}
	}
	&__main {
		display: grid;
		grid-template-columns: 410px 1fr;
		align-items: flex-start;
		margin-bottom: 30px;

		@media screen and (max-width: 980px) {
			grid-template-columns: 0.5fr 1fr;
		}
		@media screen and (max-width: 710px) {
			grid-template-columns: 1fr;
		}

		.team__main-photo {
			position: relative;
			z-index: 1;
			max-width: calc(100% + 45px);
			margin-right: -45px;
			margin-top: 60px;

			@media screen and (max-width: 710px) {
				max-width: 100%;
				margin: 0 auto 20px;
			}
		}
		.about__block {
			order: initial;
			padding: 65px 80px 75px;
			background-image: url(/assets/images/team_ep_bg.jpg);

			@media screen and (max-width: 980px) {
				padding: 15px 80px 35px;
			}
			@media screen and (max-width: 710px) {
				padding: 15px 20px 35px;
			}
		}
		.about__block-content {
			max-width: 650px;
			margin: 0 auto;

			p {
				padding: 12px 0;
			}
		}
		@media screen and (min-width: 1200px) {
			.about__title {
				padding-bottom: 30px;
			    font-size: 55px;
			}
		}
		.team__main-link {
			display: inline-block;
			margin-top: 45px;
			padding: 18px 55px;
			font-size: 12px;

			@media screen and (max-width: 980px) {
				margin-top: 20px;
			}
		}
	}
}
.team-list {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 50px 90px;
	margin: 0;
	padding: 0;
	list-style: none;

	@media screen and (max-width: 1480px) {
		grid-gap: 50px;
	}
	@media screen and (max-width: 1199px) {
		grid-gap: 30px;
	}
	@media screen and (max-width: 800px) {
		grid-template-columns: repeat(2,1fr);
	}
	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
	}
	.main-team-item {
		display: flex;
		flex-direction: column;
	}
	.main-team-item__photo {
	    margin-bottom: 35px;

	    @media screen and (max-width: 800px) {
			margin-bottom: 15px;
		}
	}
	.team-list-item__desc {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		@media screen and (max-width: 1023px) and (min-width: 801px), (max-width: 680px) and (min-width: 481px) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}
	.team-list-item__position {
		width: 100%;
		margin-bottom: 20px;
		text-align: left;

		@media screen and (max-width: 768px) {
			margin-bottom: 15px;
		}
	}
	.main-team-item__name {
		@media screen and (max-width: 580px) {
			text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff;
		}
	}
	.team-list-item__price {
		align-self: center;
		display: flex;
		align-items: center;
		margin-top: auto;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;

		@media screen and (max-width: 1023px) and (min-width: 801px), (max-width: 680px) and (min-width: 481px) {
			width: 100%;
			margin-top: auto;
			margin-bottom: 5px;
		}

		b {
			position: relative;
			margin-left: 25px;
			font-size: 30px;
			color: $orange;

			@media screen and (max-width: 1199px) {
				font-size: 25px;
			}
			@media screen and (max-width: 980px) {
				margin-left: 20px;
			}

			&::before {
				content: '';
				position: absolute;
				left: -15px;
				top: 50%;
				width: 1px;
  				height: 30px;
  				margin-top: -15px;
				background-color: $orange;

				@media screen and (max-width: 980px) {
					left: -11px;
				}
			}
		}
		& + .main-team-item__more {
			align-self: center;
			margin: auto 0 0;

			@media screen and (max-width: 1080px) {
				padding: 15px 20px;
			}
			@media screen and (max-width: 1023px) and (min-width: 801px) {
				margin-top: 10px;
				margin-right: auto;
			}
			@media screen and (max-width: 680px) and (min-width: 481px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	.main-team-item__more {
		margin-top: auto;
	    margin-left: auto;
	    font-size: 12px;
	}
}
.team-item-page {
	background-image: url(/assets/images/team_item_bg.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: auto;

	@media screen and (max-width: 1480px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.team-item {
	.about-form {
		margin-bottom: 25px;
	}
}
.team-item-about {
	display: grid;
	grid-template-columns: 400px 1fr;
	grid-column-gap: 85px;
	align-items: flex-start;
	margin: 30px 0 40px;

	@media screen and (max-width: 1280px) {
		grid-column-gap: 50px;
	}
	@media screen and (max-width: 980px) {
		margin-top: 20px;
	}
	@media screen and (max-width: 900px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 30px;
	}
	@media screen and  (max-width: 768px) {
		margin-bottom: 30px;
	}
	@media screen and  (max-width: 720px) {
		grid-template-columns: 1fr;
		margin-top: 0;
	}
	&__photo {
		@media screen and  (max-width: 720px) {
			margin: 0 auto;
		}
	}
	&__block {
		align-self: stretch;
		padding: 10px 20px 20px 160px;
		background-image: url(/assets/images/ep_2.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left bottom;

		@media screen and (max-width: 1280px) {
			padding-left: 60px; 
		}
		@media screen and  (max-width: 768px) {
			.about__subtitle {
				padding: 0;
			}
		}
		@media screen and  (max-width: 720px) {
			order: -1;
			padding: 10px 20px 30px;
			background-image: none;
		}
	}
	&__title {
		text-align: left;

		@media screen and (max-width: 900px) {
			padding: 20px 0;
		}

		span {
			display: block;
			margin-left: 20px;
		}
	}
	&__text {
		max-width: 710px;
		padding: 20px 0 50px;

		@media screen and (max-width: 900px) {
			grid-column: 1/-1;
			padding: 0 0 20px;
		}
	}
	&__bottom {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		column-gap: 30px;
		max-width: 635px;
	}
	&__price {
		display: flex;
		align-items: center;
		font-weight: 700;
		text-transform: uppercase;

		b {
			position: relative;
			margin-left: 35px;
			font-size: 39px;
			color: $orange;

			&::before {
				content: '';
				position: absolute;
				left: -18px;
				top: 50%;
				width: 3px;
  				height: 40px;
  				margin-top: -20px;
				background-color: $orange;
			}
		}
	}
	&__btn {
		padding: 20px 30px;
		font-size: 12px;
	}
}
.team-item-text {
	@media screen and  (max-width: 768px) {
		margin-bottom: 20px;
	}

	&__subtitle {
		padding: 70px 0 20px;
		text-transform: uppercase;
		font-size: 25px;

		@media screen and  (max-width: 768px) {
			padding: 20px 0;
		}
	}
	.check-list {
		margin: 0;
		padding: 0;
		column-count: 2;

		@media screen and  (max-width: 680px) {
			column-count: 1;
		}

		li {
			max-width: 650px;
			margin-bottom: 25px;
			padding-left: 40px;
			break-inside: avoid;

			&::before {
				display: block;
				position: absolute;
				left: 0;
				top: 0px;
			}
		}
	}
	ul {
		padding-left: 0;
		list-style: none;

		li {
			position: relative;
			display: block;
			margin-bottom: 10px;

			&::before {
				content: '';
				position: relative;
				top: 4px;
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-right: 15px;
				background-image: url(/assets/images/check.svg);
				background-repeat: no-repeat;
				background-size: 20px;
				filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
			}
		}
	}
}
.team-item-schedule {
	padding-bottom: 15px;

	.schedule {
		margin-bottom: 170px;

		@media screen and (max-width: 980px) {
			margin-bottom: 100px;
		}
		@media screen and  (max-width: 768px) {
			margin-bottom: 80px;
		}
		@media screen and  (max-width: 600px) {
			margin-bottom: 50px;
		}
	}
}
.buy-form {
	background-color: #efece9;
	padding: 40px;
	border-radius: 20px;	

	.form__field + .form__field {
		margin-top: 10px;
		@media screen and  (max-width: 768px) {
			margin-top: 0px;
		}
	}
	
	&_controls {
		grid-column: 1/-1;
		display: grid;
		grid-template-columns: 1fr auto;
		column-gap: 20px;
		row-gap: 20px;

		.form__btn {
			grid-column: initial;
			align-self: start;
			padding: 20px 50px;
		}
		@media screen and  (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}
	&_agreement {
		display: flex;
		flex-direction: column;
		row-gap: 8px;
	}
}