header {
	position: relative;
	background-image: url(/assets/images/header.png);
	background-repeat: no-repeat;
	background-position: left top;

	.header {
		position: relative;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 75px;
		grid-row-gap: 20px;
		align-items: center;
		padding-top: 30px;
		padding-bottom: 30px;

		@media (min-width: 1171px) {
			&:not(.header--main) {
				grid-row-gap: 20px;

				@media (max-width: 1280px) {
					grid-column-gap: 50px;
				}
				.header__logo {
					grid-row: 1/3;
					margin-top: 15px;
				}
				nav {
					align-self: flex-start;
				}
				.toggle-wrapper {
					display: none;
				}
				.header__phone {
					margin-left: 0;
				}
				.header__account  {
					margin-left: auto;
				}
			}
		}

		&--main {
			grid-template-columns: auto 1fr;
			grid-column-gap: 50px;
			padding-bottom: 0;
			padding-top: 25px;

			@media (max-width: 1280px) {
				grid-template-columns: 300px 1fr;
			}
			@media (min-width: 1171px) {
				.header__logo {
					grid-row: 1/3;					
					margin-bottom: 15px;
				}
				.header__phone {
					grid-row: 3/4;
					grid-column: 1/2;
				}
				.header__account {
					grid-row: 2/3;
					grid-column: 2/3;
					align-self: flex-start;
					margin-left: auto;
				}
				nav {
					grid-row: 1/2;
					grid-column: 2/3;
					align-self: flex-end;
					width: 100%;
					max-width: 1040px;
					margin-left: auto;
					padding-top: 10px;
				}
			}
		}
		@media (max-width: 1170px) and (min-width: 769px) {
			grid-template-columns: 300px 1fr auto;
			grid-column-gap: 30px;

			.header__logo {
				grid-row: 1/2;
				grid-column: 1/2;
			}
			.header__account {
				grid-row: 1/2;
				grid-column: 3/4;
			}
			nav {
				grid-row: 2/3;
				grid-column: 1/-1;
			}
			@media (max-width: 860px) {
				grid-template-columns: 200px 1fr auto;
				grid-column-gap: 20px;
			}
		}
		@media (max-width: 768px) {
			grid-template-columns: 250px 1fr 40px;
			grid-column-gap: 15px;
			grid-row-gap: 5px;
			padding-top: 10px;
			padding-bottom: 15px;

			.header__logo {
				// grid-row: 1/3;
				grid-row: 1/2;
				grid-column: 1/2;
			}
			.header__phone {
				grid-row: 1/2;
				grid-column: 2/3;
			}
			.header__account {
				// grid-row: 2/3;
				grid-row: 1/2;
				grid-column: 2/3;
			}
			nav {
				grid-column: 1/-1;
			}
			.toggle-wrapper {
				grid-row: 1/2;
				// grid-row: 1/3;
			}
		}
		.menu_wrap .header__account {
			display: none;
			width: 100%;
			max-width: 180px;
			margin: 0 auto;
			padding: 10px;
			
			&.header__user {
				min-height: 34px;
				background-size: 34px;
				padding: 0 42px 0 0;
				justify-content: center;
				line-height: 1.4em;
				width: auto;
				max-width: initial;
			}
		}
		@media (max-width: 600px) {
			grid-template-columns: 200px 1fr 40px;
		}
		@media (max-width: 520px) {
			grid-template-columns: 200px 40px;
			justify-content: space-between;

			.header__account {
				display: none;
			}
			.menu_wrap .header__account {
				display: block;
				&.header__user {
					display: flex;
				}
			}
		}
		@media (max-width: 420px) {
			grid-template-columns: 170px 40px;
		}
	}
}
.header__logo {
	line-height: 0;
}
.header__phone {
	margin-left: 60px;
	font-size: 20px;
	font-weight: 700;

	@media (max-width: 1170px) {
		margin: 0 auto;
	}
	@media (max-width: 600px) {
		font-size: 18px;
	}
	@media (max-width: 520px) {
		font-size: 16px;
	}
	@media (max-width: 440px) {
		padding-left: 0;
		font-size: 14px;

		&::before {
			display: none;
		}
	}
	@media (max-width: 400px) {
		font-size: 12px;
	}
}
.header__account {
	padding: 15px 50px;
	font-size: 12px;
	box-shadow: none;

	&.header__user {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		text-align: left;
		min-height: 42px;
		padding: 0 55px 0 0;
		background: url('/assets/images/icon_person.svg') right center / 42px no-repeat;
	}

	@media (max-width: 860px) {
		padding: 15px 30px;
	}
	@media (max-width: 768px) {
		margin: 0 auto;
		padding: 10px 30px;
	}
	@media (max-width: 440px) {
		padding: 10px 15px;
		font-size: 11px;
		line-height: 1.1;
	}
	@media (max-width: 400px) {
		font-size: 10px;
	}
}
.menu_wrap {
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 10;
		width: 100%;
		padding: 10px;
		background-color: #fff;
		box-shadow: 0.209px 2.993px 13px 0px rgba(0, 0, 0, 0.27);
	}
}
.menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	font-size: 15px;
	list-style: none;

	@media (max-width: 980px) {
		max-width: 100%;
	}
	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;
	}

	> li {
		position: relative;
		text-align: center;

		> a {
			position: relative;
			display: block;
			padding: 5px 0 10px;

			&:hover {
				color: $orange;
			}
		}
		@media (min-width: 769px) {
			a.active::after {
				content: '';
				position: absolute;
				left: -25%;
				bottom: 0;
				width: 150%;
				height: 7px;
				border-radius: 150px/15px;
				background-image: $gradient;
			}
		}
		@media (max-width: 768px) {
			a.active {
				color: $orange;
			}
		}
	}
	ul {
		display: none;
	}
}
.toggle-wrapper {
	display: none;

    @media (max-width: 768px) {
    	display: block;
    }
}
/* icon basic styles !!!YOU NEED THEM  */
.menu-icon {
  height: 25px;
  cursor: pointer;
  width: 40px;
  position: relative;
  overflow: hidden;
}
.menu-icon:before,
.menu-icon:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  content: "";
  display: block;
  width: 4px;
  height: 28px;
  border-radius: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background-color: $orange;
}
.menu-icon:before {
  position: absolute;
  top: 0;
  left: 18px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu-icon:after {
  position: absolute;
  top: 0;
  right: 18px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-icon .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  width: 100%;
  height: 5px;
  background-color: $orange;
  border-radius: 0;
}
.menu-icon .bar-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-icon .bar-2 {
  position: absolute;
  top: 10px;
  left: 0;
}
.menu-icon .bar-3 {
  position: absolute;
  top: 20px;
  left: 0;
}
.menu-icon.opened .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.menu-icon.opened:before,
.menu-icon.opened:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menu-icon-3:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-icon-3:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu-icon-3:before,
.menu-icon-3:after {
  top: -2px;
}

.menu-icon-3.opened:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-icon-3.opened:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-icon-3.opened:before,
.menu-icon-3.opened:after {
  top: 0px;
}
.menu-icon-3.opened .bar {
  opacity: 0;
}
.menu-icon-3.opened .bar-1 {
  top: 10px;
}
.menu-icon-3.opened .bar-3 {
  top: 10px;
}