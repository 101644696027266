@font-face {
	font-family: "Merriweather";
	src: url("../fonts/Merriweather-Regular.woff") format("woff"),
	url("../fonts/Merriweather-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "Merriweather";
	src: url("../fonts/Merriweather-Bold.woff") format("woff"),
	url("../fonts/Merriweather-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: "Merriweather";
	src: url("../fonts/Merriweather-Light.woff") format("woff"),
	url("../fonts/Merriweather-Light.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}