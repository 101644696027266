.personal-consultation {
	padding-bottom: 110px;

	@media (max-width: 1199px) {
		padding-bottom: 60px;
	}
	@media (max-width: 768px) {
		padding-top: 0;
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	&__text {
		position: relative;
		max-width: 1170px;
		margin: 0 auto 100px;
		padding: 15px 0 15px 100px;

		@media screen and (max-width: 1199px) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: 980px) {
			margin-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
			padding-left: 30px;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 13.5px;
			height: 100%;
			background-image: linear-gradient(to bottom, rgb(255,248,233) 0%, rgb(255,147,5) 100%);

			@media screen and (max-width: 768px) {
				width: 10px;
			}
		}
		p {
			max-width: 1255px;
			padding: 12px 0;
		}
	}
	.team-item-about {
		margin-bottom: 90px;

		@media (max-width: 1199px) {
			margin-bottom: 60px;
		}
		@media screen and  (max-width: 768px) {
			grid-template-columns: 1fr;
			margin-bottom: 30px;

			&__photo {
				margin: 0 auto;
			}
			&__block {
				order: -1;
				padding: 10px 20px 30px;
				background-image: none;
			}
		}
		&__title {
			padding-bottom: 30px;
		}
		&__smtitle {
			padding: 0 0 20px;
			text-transform: uppercase;
			font-size: 25px;

			@media screen and (max-width: 768px) {
				padding-bottom: 15px;
			}
		}
		p {
			max-width: 750px;
		}
		&__btn {
			flex-grow: 1;
			max-width: 304px;

			span {
				position: relative;
				padding-left: 40px;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 26px;
					height: 24px;
					margin-top: -12px;
					background-image: url(/assets/images/chat_icon.svg);
					background-position: center;
					background-size: 100%;
				}
			}
		}
	}
	&__btns {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		row-gap: 10px;
		column-gap: 15px;
		max-width: 565px;
		margin-top: 45px;

		@media screen and (max-width: 980px) {
			margin-top: 20px;
		}
	}
	&__link {
		flex-grow: 1;
		max-width: 210px;
		padding: 20px; 
		text-transform: uppercase;
		font-size: 12px;
		background-color: #fff;
	}
	@media screen and (min-width: 1200px) {
		.team-list {
			margin-top: 40px;
		}
	}
	.team-list {
		&__item {
			padding: 0 25px 10px; 

			@media screen and (max-width: 1600px) {
				padding: 0 20px 10px; 
			}
			@media screen and (max-width: 1199px) {
				padding: 0 15px 10px; 
			}
			@media screen and (max-width: 768px) {
				padding: 0 10px 10px;
			}
		}
	}
}