@import "normalize.scss";
@import "jquery.fancybox.scss";
@import "fonts.scss";
@import "var.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container {
  max-width: 1560px;
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px 45px;
  font-family: $m;

  @media screen and  (max-width: 680px) {
    grid-gap: 20px;
  }
  @media screen and  (max-width: 440px) {
    grid-template-columns: 1fr;
  }

  label {
    display: block;
    margin-bottom: 15px;
    text-align: left;
  }
  input {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    border: none;
    border-bottom: 1px solid #000;
    background: none;
    transition: 0.2s;

    &:focus {
      outline: none;
      border-bottom-color: $orange;
    }
  }
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 13px;
    border: 1px solid #000;
    background: none;
    transition: 0.2s;

    &:focus {
      outline: none;
      border-color: $orange;
    }
  }
  label,
  ::-webkit-input-placeholder {color:#363636; font-size: 13px; text-transform: uppercase;}
  ::-moz-placeholder          {color:#363636; font-size: 13px; text-transform: uppercase;}/* Firefox 19+ */
  :-moz-placeholder           {color:#363636; font-size: 13px; text-transform: uppercase; }/* Firefox 18- */
  :-ms-input-placeholder      {color:#363636; font-size: 13px; text-transform: uppercase;}

  .form__field {
    position: relative;
  }
  .form__textarea {
    @media screen and (min-width: 441px) {
      grid-row: 1/4;
      grid-column: 2/3;
    }
    position: relative;
    display: flex;
    flex-direction: column;

    textarea {
      flex-grow: 1;
      min-height: 110px;
    }
  }
  .form__check {
    grid-column: 1/-1;

    label {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 45px;
      min-height: 33px;
      margin: 0;
      font-size: 13px;
      line-height: 1.2;
      color: #363636;
      text-transform: none;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 33px;
        height: 33px;
        border-style: solid;
        border-width: 1px;
        border-color: #000;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 22px;
      }
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    input:checked + label::before {
      background-image: url(/assets/images/checked.svg);
    }
  }
  .form__btn {
    grid-column: 1/-1;
    position: relative;
    margin: 0 auto;
    padding: 20px 55px;
    font-size: 14px;
    transition: 0.2s;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }
  .form__error {
    position: absolute;
    top: 100%;
    display: block;
    padding-top: 1px;
    color: red;
    font-size: 13px;
    text-align: left;

    @media screen and (max-width: 520px) {
      font-size: 11px;
    }
    &--static {
      position: static;
    }
  }
  &--notext {
    @media screen and (min-width: 769px) {
      .form__check {
        grid-column: auto;
        align-self: flex-end;
        margin-right: -15px;
      }
      .form__btn {
        margin-top: 45px;
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      input[name="name"] {
        grid-column: 1/-1;
      }
    }
  }
}
.tel {
  position: relative;
  padding-left: 35px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 22px;
    height: 22px;
    margin-top: -9px;
    background-image: url(/assets/images/tel.svg);
    background-size: 120%;
    background-position: center;
  }
}
.mail {
  position: relative;
  padding-left: 40px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 25px;
    height: 16px;
    margin-top: -8px;
    background-image: url(/assets/images/mail.svg);
    background-size: 120%;
    background-position: center;
  }
}
.check-list {
  list-style: none;

  li {
    position: relative;

    &::before {
      content: '';
      position: relative;
      top: 4px;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      background-image: url(/assets/images/check.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
    }
  }
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

a {color: inherit;}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $normal;
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
  background-color: #fff;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0.5em 0;
}

.uppercase {
  text-transform: uppercase;
}
.center {
  text-align: center;
}

h1, h2, h3, .h2 {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 55px 0;
  font-size: 45px;
  line-height: 1.2;
  font-family: $normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 1199px) {
    padding: 35px 0;
    font-size: 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 25px 0;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
}
h1 { 
  font-size: 55px;

  @media screen and (max-width: 1199px) {
    font-size: 45px;
  }
  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
}
.btn {
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }
}
.gradient {
  background-color: #fff;
  background-image: $gradient;

  &:hover {
    background-image: linear-gradient( to right, rgb(255,248,233) 0%, rgb(255,147,5) 100%);
  }
}
.swiper-pagination {
  .swiper-pagination-bullet {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 7px;
    padding: 0;
    border-radius: 50%;
    background-color: rgb(26, 26, 24);
    opacity: 0.502;

    &::before {
      display: none;
    }
    &-active,
    &:hover {
      opacity: 0.2;
    }
  }
}

@import "block/header.scss";
@import "block/footer.scss";
@import "block/main.scss";
@import "block/about.scss";
@import "block/services.scss";
@import "block/contacts.scss";
@import "block/video.scss";
@import "block/personal.scss";
@import "block/news.scss";
@import "block/faq.scss";
@import "block/webinar.scss";
@import "block/kons.scss";
@import "block/test.scss";
@import "block/popup.scss";
@import "block/group.scss";
@import "block/retreat.scss";
@import "block/course.scss";
@import "block/edgroup.scss";
@import "block/login.scss";
@import "block/schedule.scss";

.breadcrumbs {
  margin: 0;
  padding: 15px 0;
  font-size: 12px;
  list-style: none;
  text-transform: uppercase;

  li {
    display: inline-block;
  }
  a {
    position: relative;
    color: $orange;

    &:hover {
    }

    &::after {
      content: ' - ';
      color: #000;
    }
  }
}

.mobile_tel {
  color: inherit;
  cursor: auto;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 30px 0;
  list-style: none;

  li {
    position: relative;
    margin: 0 0 5px;
    font-size: 20px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

    span:not(.btn) {
      position: relative;
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      border-radius: 50%;

      @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
    &.active span,
    a:not(.pagination__arrow):hover {
      background-image: linear-gradient(to right, rgba(255,222,179,0.5) 0%, rgba(255,147,5,0.5) 100%);
    }
    &.pagination__all {
      @media screen and (min-width: 1199px) {
        margin-left: 30px;
      }
      @media screen and (min-width: 900px) {
        margin-left: 15px;
      }

      .btn {
        display: inline-block;
        padding: 20px 60px;
        font-size: 15px;
        border-radius: 30px;
        background-image: linear-gradient(to right, rgba(255,222,179,0.5) 0%, rgba(255,147,5,0.5) 100%);
        box-shadow: none;

        @media screen and (max-width: 900px) {
          padding: 20px 35px;
        }
        @media screen and (max-width: 768px) {
          padding: 15px 25px;
          font-size: 12px;
        }
        &:hover {
          box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .disabled {
    opacity: 0.8;
  }
}


.compensate-for-scrollbar {
    margin-right: 0!important;
}
.anim {
  .enter {
    opacity: 0.01;
  }
  .enter-active {
    opacity: 0.01;
  }
  .enter-done {
    opacity:1; 
    transition: opacity 150ms linear;
  }
  .exit {
    opacity: 1;
  }
  .exit-active {
    opacity: 0.01;
    transition: opacity 150ms linear;
  }
}
.swal2-container {
  z-index: 100000;
}
.swal2-popup {
  font-size: 15px;

  .swal2-title {
    font-size: 26px;
    font-family: "Montserrat-Bold", Verdana, Helvetica;
  }
  .swal2-confirm {
    background-color: #7bb638;
    color: #fff;
    font-size: 16px;
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.button_top {
  position: fixed;
  right: 3%;
  bottom: 100px;
  z-index: 100;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 60px;
  height: 60px;
  background-color: $orange;
  border-radius: 50%;
  border: none;
  box-shadow: inset 0px 0px 15px 0px rgba(255, 255, 255, 0.8);
  opacity: 0.6;

  &::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
		background-image: url(/assets/images/down_arrow.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 35px;
		transform: scale(1,-1);
		filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(322deg) brightness(103%) contrast(101%);
	}

  &:hover {
    opacity: 1;
    box-shadow: none;
  }
}
.gdpr {
  position: fixed;
  top: auto;
  bottom: 0;
  height: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  box-shadow: 0px 3px 38px 0px rgba(99, 99, 99, 0.38);
  padding: 15px 0;
  font-weight: 600;
  z-index: 1000;
}
.gdpr--white {
  background-color: rgba(255, 255, 255, 0.9);
  color: #090f1f;
  border-top: 3px solid #191919;
}
.gdpr .container {
  display: grid;
  grid-template-columns: 1fr 244px;
  grid-column-gap: 80px;
  align-items: center;
  box-sizing: border-box;
}
.gdpr .container:before,
.gdpr .container:after {
  display: none;
}
.gdpr p,
.gdpr small {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: 14px;
  line-height: 1.3;
}
.gdpr .container > small {
  display: none;
}
.gdpr_close {
  grid-row: 1/3;
  grid-column: 2/3;
  display: block;
  margin: 0;
  padding: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
}
.gdpr--white .gdpr_close {
  background-color: #000000;
  color: #fff;
}
.gdpr_close:hover {
  color: #000;
}
.gdpr--white .gdpr_close:hover {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .gdpr .container {
    grid-template-columns: 1fr 200px;
    grid-column-gap: 40px;
  }
}
@media screen and (max-width: 600px) {
  .gdpr {
    padding: 20px 0 15px;
  }
  .gdpr .container {
    grid-template-columns: 1fr 175px;
    grid-column-gap: 35px;
  }
  .gdpr p {
    grid-column: 1/-1;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .gdpr p small {
    display: none;
  }
  .gdpr .container > small {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }
  .gdpr_close {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 13px;
  }
}
@media screen and (max-width: 400px) {
  .gdpr .container {
    grid-template-columns: 1fr 145px;
    grid-column-gap: 20px;
  }
  .gdpr_close {
    padding: 15px 10px;
  }
}