.news-page {
	background-image: url(/assets/images/news_bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left top;

	@media screen and (max-width: 1920px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.news {
	padding-bottom: 70px;

	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
}
.news-list {
	margin: 5px 0 70px;
	padding-left: 35px;
	list-style: none;

	@media screen and (max-width: 1199px) {
	    margin-bottom: 50px;
	}
	@media (max-width: 768px) {
		margin: 0 0 20px;
	}
	@media screen and (max-width: 1280px) {
	    padding-left: 0;
	}

	li {
		position: relative;
		margin-bottom: 70px;

		@media screen and (max-width: 1199px) {
		    margin-bottom: 50px;
		}
		@media (max-width: 768px) {
			margin-bottom: 30px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 15%;
			width: 85%;
			height: 100%;
			background-color: #efece9;

			@media screen and (max-width: 680px) {
			    top: 15%;
				left: 0;
				width: 100%;
				height: 85%;
			}
		}
		&:nth-child(even)::before {
			background-color: #f9f6f3;
		}
	}
	&--blog {
		li {
			position: relative;
			margin-bottom: 95px;

			@media screen and (max-width: 1199px) {
			    margin-bottom: 50px;
			}
			@media (max-width: 768px) {
				margin-bottom: 30px;
			}
		}
	}
}
.news-list-item {
	position: relative;
	z-index: 1;
	display: grid;
	grid-template-columns: 585px 1fr;
	grid-gap: 0 30px;
	align-items: flex-start;
	padding: 85px 0 95px;

	@media screen and (max-width: 1480px) {
	    padding-right: 20px;
	}
	@media screen and (max-width: 1280px) {
	    padding: 40px 20px 45px 0;
	}
	@media screen and (max-width: 1170px) {
	    grid-template-columns: 0.8fr 1fr;
	}
	@media screen and (max-width: 680px) {
	    grid-template-columns: 1fr;
	    padding: 0 0 30px;
	}

	&__text,
	&__name,
	&__date,
	&__link {
		width: 100%;
		max-width: 685px;
		margin: 0 auto;

		@media screen and (max-width: 680px) {
		   padding-left: 20px;
		   padding-right: 20px;
		}
	}
	&__photo {
		grid-row: 1/5;
		border-radius: 45px 0 45px 0;

		@media screen and (max-width: 680px) {
		   margin-bottom: 15px;
		}
	}
	&__date {
		font-size: 15px;
		font-weight: 500;
		font-family: $m;
		text-align: left;
	}
	&__name {
		margin-top: 20px;
		margin-bottom: 30px;
		line-height: 1.2;
		font-size: 30px;
		text-transform: uppercase;

		@media (max-width: 1480px) {
			font-size: 27px;
		}
		@media (max-width: 1080px) {
			margin-top: 10px;
			margin-bottom: 15px;
			font-size: 25px;
		}
		@media (max-width: 900px) {
			font-size: 20px;
		}
	}
	&__text {
		margin-bottom: 55px;

		@media (max-width: 1080px) {
			margin-bottom: 25px;
		}
		ul {
			list-style: none;
			padding-left: 0;

			li {
				position: relative;
				display: block;
				margin-bottom: 10px;

				&::before {
					content: '';
					position: relative;
					top: 4px;
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 15px;
					background-image: url(/assets/images/check.svg);
					background-repeat: no-repeat;
					background-size: 20px;
					filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
				}
			}
		}
	}
	
	&__link .btn {
		display: block;
		width: 160px;
   		padding: 15px;
   		text-align: center;
   		font-size: 12px;

		&:hover {
			box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
		}
	}
}
.news-list--blog .news-list-item {
	padding: 75px 0 0;

	@media screen and (max-width: 1480px) {
	    padding-right: 20px;
	}
	@media screen and (max-width: 1280px) {
	    padding: 40px 20px 0 0;
	}
	@media screen and (max-width: 680px) {
	    padding: 0;
	}	
	.news-list-item__photo {
		margin-bottom: -55px;

		@media screen and (max-width: 680px) {
		   margin-bottom: 20px;
		}
	}
	.news-list-item__name {
		margin-top: 0;
	}
	.news-list-item__text {
		margin-bottom: 45px;

		@media (max-width: 1080px) {
			margin-bottom: 25px;
		}
	}
	.news-list-item__link {
		padding-bottom: 45px;

		@media (max-width: 1080px) {
			padding-bottom: 25px;
		}
	}
}
.news-item-page {
	background-image: url(/assets/images/news_item_bg.jpg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left top;

	@media screen and (max-width: 1920px) {
	    background-size: 100% auto;
		background-position: left -5%;
	}
	@media screen and (max-width: 1480px) {
		background-position: left top;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.news-item {
	padding-bottom: 60px;

	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	h1 {
		padding: 30px 0 40px;
	}
	&__date {
		padding: 5px 0 0;
		text-align: center;

		span {
			display: inline-block;
			padding: 20px 70px;
			font-size: 15px;
			font-weight: 500;
			font-family: $m;
			border-radius: 30px;
  			background-color: rgb(223, 238, 241);
		}
	}
	&__photo {
		display: block;
		width: 100%;
		max-width: 1024px;
		margin: 0 auto 45px;

		@media screen and (max-width: 768px) {
		    margin-bottom: 20px;
		}
	}
	&__text {
		width: 100%;
		max-width: 1270px;
		margin: 0 auto;

		p {
			padding: 12px 0;
		}
		.check-list {
			margin: 0 0 60px;
			padding: 15px 0 0;
			font-size: 15px;
			font-weight: 700;

			@media screen and (max-width: 1199px) {
			    margin-bottom: 30px;
			}
			@media screen and (max-width: 768px) {
			    margin-bottom: 10px;
			}
		}
	}
	&__lead {
		width: 100%;
		max-width: 1270px;
		padding-top: 5px;
		margin: 0 auto 45px;
		font-size: 14px;

		p {
			padding: 12px 0;
		}
	}
	&__orange {
		margin: 30px 0;
		color: $orange;
		font-size: 20px;
		font-weight: 700;

		@media screen and (max-width: 768px) {
		    margin: 15px 0;
		}

		span {
			font-size: 30px;
		}
	}
	&__block {
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		grid-column-gap: 80px;
		align-items: center;
		margin: 100px 0;

		@media screen and (max-width: 1280px) {
		    grid-column-gap: 50px;
		    margin: 50px 0;
		}
		@media screen and (max-width: 980px) {
		    display: block;
		    margin: 0;
		}

		img {
			order: 2;	

			@media screen and (max-width: 980px) {
			    display: block;
			    margin: 30px auto;
			}		
		}
	}
	&__video {
        position: relative;
        margin-bottom: 35px;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
			border: none;
        }
	}
}
.news-item__back {
	display: block;
	max-width: 255px;
	width: 100%;
	margin: 40px 0 155px;
	padding: 20px 40px 20px 65px;
	font-size: 12px;
  	background-repeat: no-repeat;
  	background-position: 30px center, center;
  	background-image: 
  		url(/assets/images/arrow_left.svg),
  		linear-gradient(to right, rgb(255,248,233) 0%, rgb(249,246,243) 100%);
  	background-size: 17px, 100%;

  	@media screen and (max-width: 1199px) {
	    margin: 20px 0 100px;
	}

  	@media screen and (max-width: 768px) {
	    margin-bottom: 40px;
	}

  	&:hover {
  		box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
  	}
}
.blog__text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1070px;
	min-height: 201px;
	margin: 0 auto 90px;
	padding: 0 0 0 245px;
	font-size: 30px;
	background-image: url(/assets/images/logo_xs.svg);
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 201px;

	@media screen and (max-width: 991px) {
	    margin-bottom: 50px;
	}
	@media screen and (max-width: 900px) {
	    font-size: 25px;
	}
	@media screen and (max-width: 768px) {
	    font-size: 20px;
	}
	@media screen and (max-width: 600px) {
		min-height: 120px;
		padding: 0 0 0 150px;
	    background-size: 120px;
	}
	@media screen and (max-width: 420px) {
	    font-size: 17px;
	}
	@media screen and (max-width: 360px) {
	    font-size: 15px;
	}
}
.blog-item-page {
	background-image: url(/assets/images/blog_item_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: left top;

	@media screen and (max-width: 1480px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.news-item--blog {
	.news-item__back {
		margin-top: 60px;
	  	background-image: 
	  		url(/assets/images/arrow_left.svg),
	  		$gradient;

	  	@media screen and (max-width: 1199px) {
		    margin-top: 30px;
		}
	}
}
