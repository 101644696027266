.courses-page {
	background-image: url(/assets/images/courses_bg.jpg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;

	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.webinars-page {
	background-image: url(/assets/images/webinars_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center top;

	@media screen and (max-width: 1480px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.webinars {
	padding-bottom: 70px;

	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	&__text {
		max-width: 1190px;
		margin: 0 auto 110px;

		@media screen and (max-width: 1199px) {
		    margin-bottom: 70px;
		}
		@media screen and (max-width: 980px) {
		    margin-bottom: 50px;
		}
		@media (max-width: 768px) {
			margin-bottom: 30px;
		}
		.check-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
			margin: 30px 0 0;
			padding: 0;

			@media (max-width: 680px) {
				grid-template-columns: 1fr;
				grid-gap: 20px;
				margin-top: 15px;
			}

			li {
				padding-left: 40px;

				&::before {
					display: block;
					position: absolute;
					left: 0;
					top: 0px;
				}
			}
		}
	}
}
.webinars-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 100px 50px;
	margin: 0 0 70px;
	padding: 0;
	list-style: none;

	@media screen and (max-width: 1280px) {
	    grid-gap: 80px 30px;
	}
	@media screen and (max-width: 1199px) {
	    margin-bottom: 50px;
	}
	@media screen and (max-width: 860px) {
	    grid-template-columns: repeat(2, 1fr);
	    grid-gap: 40px 30px;
	}
	@media (max-width: 768px) {
		margin: 0 0 20px;
	}
	@media screen and (max-width: 480px) {
	    grid-template-columns: 1fr;
	}
	li {
		position: relative;
		display: flex;
		flex-direction: column;

		&::before {
			content: '';
			position: absolute;
			top: 23%;
			left: 14%;
			width: 86%;
			height: 77%;
			background-color: #efece9;

			@media screen and (max-width: 680px) {
			    left: 10%;
				width: 90%;
			}
		}
	}
}
.webinars-list-item {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: flex-start;

	&__photo {
		width: 88%;
		border-radius: 45px 0 45px 0;
	}
	&__text {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 88%;
		margin-left: auto;
		padding: 40px 40px 50px;

		@media screen and (max-width: 1199px) {
		    padding: 30px;
		}
		@media screen and (max-width: 680px) {
			width: 90%;
		   	padding-left: 20px;
		   	padding-right: 20px;
		}
	}
	&__date {
		position: relative;
		margin-bottom: 20px;
		padding-left: 45px;
		font-size: 15px;
		font-weight: 500;
		font-family: $m;
		text-align: left;
		text-transform: uppercase;

		@media screen and (max-width: 768px) {
		   margin-bottom: 15px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: -8px;
			width: 35px;
			height: 35px;
			background-image: url(/assets/images/calendar_icon.svg);
			background-size: 35px;
			background-position: center;
		}
	}
	&__name {
		margin-bottom: 30px;
		line-height: 1.2;
		font-size: 25px;
		text-transform: uppercase;

		@media (max-width: 1480px) {
			font-size: 23px;
		}
		@media (max-width: 1080px) {
			margin-bottom: 20px;
			font-size: 20px;
		}
		@media (max-width: 900px) {
			font-size: 18px;
		}
		@media screen and (max-width: 768px) {
		   margin-bottom: 15px;
		}
	}
	&__link {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		column-gap: 15px;
		row-gap: 15px;
		margin-top: auto;

		.btn {
			flex-grow: 1;
			max-width: 160px;
	   		padding: 15px;
	   		text-align: center;
	   		font-size: 12px;

			&:hover {
				box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
			}
		}
	}
	&__price { 
		color: orange;
		font-size: 20px;
		font-weight: 700;
		line-height: 1;

		@media screen and (max-width: 400px) {
		    font-size: 18px;
		}

		b {
			font-size: 30px;

			@media screen and (max-width: 400px) {
			    font-size: 25px;
			}
		}
	}
}
.webinar-item-page {
	background-image: url(/assets/images/webinar_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: right top;

	@media screen and (max-width: 1480px) {
	    background-size: 50% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.webinar-item {
	padding-top: 10px;
	padding-bottom: 15px;

	@media (max-width: 768px) {
		padding-top: 0;
		padding-bottom: 30px;
	}
	&__grid {
		display: grid;
		grid-template-columns: 1fr 620px;
		grid-template-rows: auto auto 1fr;
		grid-column-gap: 50px;
		align-items: flex-start;
		padding: 70px 0;

		@media screen and (max-width: 1199px) {
		   	grid-template-columns: 1fr 0.8fr;
		   	grid-column-gap: 30px;
		   	padding: 50px 0;
		}
		@media screen and (max-width: 768px) {
		   	grid-template-columns: 1fr 1fr;
		   	align-items: center;
		   	padding: 30px 0;
		}
		@media screen and (max-width: 520px) {
		   	grid-template-columns: 1fr;
		   	grid-template-rows: initial;
		}
		h1 {
			max-width: 750px;
			padding: 0 0 55px;
			text-align: left;

			@media screen and (max-width: 1199px) {
			   	padding-bottom: 30px;
			}
			@media screen and (max-width: 768px) {
			   	grid-column: 1/-1;
			}
		}
	}
	&__date {
		position: relative;
		padding: 0 0 45px 45px;
		font-size: 15px;
		font-weight: 500;
		font-family: $m;
		text-align: left;
		text-transform: uppercase;

		@media screen and (max-width: 1199px) {
		   	padding-bottom: 30px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: -8px;
			width: 35px;
			height: 35px;
			background-image: url(/assets/images/calendar_icon.svg);
			background-size: 35px;
			background-position: center;
		}
	}
	&__photo {
		@media screen and (min-width: 769px) {
		   	grid-row: 1/5;
			grid-column: 2/3;
		}
		@media screen and (max-width: 768px) and (min-width: 521px) {
		   	grid-row: 2/4;
			grid-column: 2/3;
		}
		@media screen and (max-width: 520px) {
		   	grid-row: 3/4;
		   	margin-bottom: 15px;
		}
		border-radius: 45px 0 45px 0;
	}
	&__note {
		display: flex;
		align-items: center;
		position: relative;
		font-family: "Montserrat";
		font-weight: 500;
		color: #ff9100;
		text-transform: uppercase;
		padding-left: 40px;
		margin-bottom: 10px;
		font-size: 15px;
		min-height: 28px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 28px;
			height: 28px;
			filter: invert(59%) sepia(93%) saturate(1668%) hue-rotate(357deg) brightness(96%) contrast(110%);
		}
		&--online::before {
			background: url(/assets/images/online.svg) center center / 100% no-repeat;
		}
		&--demo::before {
			background: url(/assets/images/demo.svg) center center / 100% no-repeat;
		}
	}
	&__btns {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		column-gap: 15px;
		row-gap: 15px;
		max-width: 620px;
		margin-top: 35px;
		@media screen and (max-width: 992px) {
		    margin-top: 20px;
		}
	}
	&__link {
		flex-grow: 1;
		max-width: 285px;
   		padding: 20px;
   		text-align: center;
   		font-size: 15px;
   		border-radius: 36px;

		&:hover {
			box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
		}
	}
	&__price { 
		color: orange;
		font-size: 25px;
		font-weight: 700;
		line-height: 1;

		@media screen and (max-width: 680px) {
		    font-size: 20px;
		}

		b {
			font-size: 35px;

			@media screen and (max-width: 680px) {
			    font-size: 30px;
			}
		}
	}
	&__text {
		position: relative;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto 110px;
		padding-left: 90px;

		@media screen and (max-width: 1199px) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: 980px) {
			margin-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
			padding-left: 30px;
		}
		&::after {
			content: '';
			position: absolute;
			top: 2%;
			left: 0;
			width: 13.5px;
			height: 96%;
			background-image: linear-gradient(to top, rgb(255,248,233) 0%, rgb(255,147,5) 100%);

			@media screen and (max-width: 768px) {
				width: 10px;
			}
		}
		p {
			max-width: 1255px;
			padding: 12px 0;
		}
		ul {
			list-style: none;
			padding-left: 0;

			li {
				position: relative;
				display: block;
				margin-bottom: 10px;

				&::before {
					content: '';
					position: relative;
					top: 4px;
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 15px;
					background-image: url(/assets/images/check.svg);
					background-repeat: no-repeat;
					background-size: 20px;
					filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
				}
			}
		}
	}
	&__faq {
		padding-bottom: 75px;
		h2 {
			margin-bottom: 45px;
		}
		@media screen and (max-width: 768px) {
			padding-bottom: 45px;
			h2 {
				margin-bottom: 15px;
			}
		}
	}
	&_buy {
		padding-top: 30px;
		padding-bottom: 30px;

		h2 {
			padding-bottom: 45px;
		}

		&__row {
			display: flex;
			justify-content: space-between;
			row-gap: 30px;
			flex-wrap: wrap;
			max-width: 1430px;
		}
		&__text {
			width: 33%;
			h2 {
				text-align: left;
				text-transform: none;
				padding-top: 45px;
				padding-bottom: 35px;
			}
			@media screen and (max-width: 992px) {
				width: 100%;
			}
		}
		&__form {
			width: 63%;
			form {
				display: block;
				padding: 45px 70px;
			}
			.buy-form__grid {
				row-gap: 30px;				
			}
			@media screen and (max-width: 1199px) {				
				form {
					padding: 30px 40px;
				}
			}
			@media screen and (max-width: 992px) {
				width: 100%;
			}
			@media screen and (max-width: 768px) {				
				form {
					padding: 20px;
				}
			}
		}
	}
}

