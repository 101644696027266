.schedule {
    max-width: 640px;
    margin: 0 auto;

    &__title {
        padding-bottom: 15px;
    }
    &__text {
        padding: 0 0 30px;
        text-align: center;
        font-size: 25px;

        @media screen and (max-width: 768px) {
			font-size: 22px;
		}

        @media screen and (max-width: 680px) {
			font-size: 19px;
		}

        span {
            position: relative;
            display: inline-block;
            padding-left: 35px;
            font-size: 16px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 24px;
                height: 24px;
                margin-top: -12px;
                background-image: url(/assets/images/clock.png);
            }
        }
    }
    &-row {
        display: grid;
        grid-template-columns: repeat(auto-fill, 70px);
        gap: 10px 25px;
        margin-bottom: 30px;

        @media screen and (max-width: 680px) {
			column-gap: 10px;
		}

        &__date {
            grid-column: 1/-1;
            padding: 0 0 5px;
            font-size: 16px;
        }
        button {
            padding: 10px 3px;
            text-align: center;
            color: #151515;
            font-size: 16px;
            line-height: 1.1;
            background-color: rgb(250, 212, 161);
            border: none;
            border-radius: 6px;
            cursor: pointer;

            &:hover,
            &.active {
                background-color: #fe9516;
            }
            &:active {
                box-shadow: inset 0 0 rbga(0,0,0,0.6);
            }
        }
    }
    &__more {
        margin-bottom: 20px;

        button {
            position: relative;
            padding: 0 20px 0 0;
            font-size: 14px;
            color: $orange;
            background: none;
            border: none;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                width: 13px;
                height: 8px;
                margin-top: -4px;
                font-size: 0;
                background-image: url(/assets/images/down_arrow.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 18px;
                // transform: scale(1,-1);
                filter: invert(72%) sepia(47%) saturate(523%) hue-rotate(340deg) brightness(102%) contrast(101%);          
            }
        }
    }
    &__submit {
        display: block;
        width: 220px;
        margin: 0 auto;
        padding: 20px;
        font-size: 12px;

        &[disabled] {
            box-shadow: none;
            background-image: linear-gradient(to right, rgba(255, 248, 233, 0.5) 0%, rgba(255, 147, 5, 0.5) 100%);
        }
    }
}