.contacts-page {
	background-image: url(/assets/images/contacts_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: left top;

	@media screen and (max-width: 1480px) {
	    background-size: 50% auto;
	}
	@media screen and (max-width: 1199px) {
		background-position: left 20%;
		background-size: 30% auto;
	}
	@media screen and (max-width: 768px) {
	    background-image: none;
	}
}
.contacts {
	padding-bottom: 110px;

	@media (max-width: 1199px) {
		padding-bottom: 80px;
	}
	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	&__block {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 30px;
		align-items: center;
		max-width: 1040px;
		margin: 20px auto 90px;

		@media screen and (max-width: 1199px) {
		    margin: 0 auto 30px;
		}
		@media (max-width: 900px) {
			grid-column-gap: 15px;
		}
		@media (max-width: 768px) {
			grid-template-columns: 100%;
			grid-row-gap: 15px;
		}
		
	}
	&__text {
		font-weight: 700;
		font-size: 18px;

		@media (max-width: 768px) {
			font-size: 16px;
			margin: 0 auto;
		}

		.tel {
			display: block;
			margin-bottom: 10px;
		  	padding-left: 40px;
		}
		.mail {
			display: block;
			text-decoration: underline;
			color: $orange;

			&:hover {
				text-decoration: none;
			}
		}
	}
	&__links {
		display: grid;
		grid-template-columns: repeat(4, 116px);
		grid-column-gap: 30px;
		grid-row-gap: 10px;
		width: 100%;
		max-width: 600px;
		margin-left: auto;

		@media (max-width: 1080px) {
			grid-column-gap: 15px;
		}
		@media (max-width: 992px) {
			width: auto;
		}
		@media (max-width: 768px) {
			margin: 0 auto;
		}
		@media (max-width: 580px) {
			grid-column-gap: 10px;
			grid-template-columns: repeat(4, 1fr);
		}
		@media (max-width: 420px) {
			width: 100%;
			max-width: 260px;
			grid-template-columns: repeat(2, 48%);
			justify-content: space-between;
			column-gap: initial;
		}
		.contacts__link {
			display: flex;
			flex-direction: column;
			height: 116px;
			padding: 10px;
			text-align: center;
			font-size: 14px;
			font-weight: 300;
		  	border-radius: 15px;
		  	background-color: rgb(249, 246, 243);

		  	@media (max-width: 420px) {
				height: 100px;
				padding: 5px;
			}
			@media (max-width: 380px) {
				height: 80px;
				font-size: 12px;

				img {
					max-width: 60%;
				}
			}

		  	img {
		  		margin: auto;
		  		transition: 0.2s;
		  	}
		  	&:hover {
		  		color: $orange;

		  		img {
		  			transform: scale(1.1);
		  			transform-origin: center;
		  		}
		  	}
		}
	}
	.about-form {
		margin-bottom: 0;
	}
}

