.retreat-page {
	background-image: 
		url(/assets/images/contacts_bg.jpg),
		url(/assets/images/retreat_bg_btm.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 
		left top,
		left bottom;

	@media screen and (max-width: 1480px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.retreat {
	padding-bottom: 140px;

	@media (max-width: 1199px) {
		padding-bottom: 80px;
	}
	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
			padding-bottom: 45px;
		}
	}
	&-block {
		position: relative;
		display: grid;
		grid-template-columns: 0.45fr 1fr;
		align-items: center;
		margin-bottom: 140px;

		@media screen and (max-width: 1280px) {
			margin-bottom: 90px;
		}
		@media screen and (max-width: 1080px) {
			margin-bottom: 50px;
		}
		@media screen and  (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-row-gap: 20px;
			margin-bottom: 30px;
		}
		@media screen and  (min-width: 769px) {
			&__photo {
				width: 250%;
				max-width: 1140px;
				order: -1;
				position: relative;
				z-index: 1;
			}
		}
		&__text {
			position: relative;
			z-index: 2;
			padding: 90px 20px 90px 160px;
			background-image: url(/assets/images/ep_2.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left bottom;
			border-radius: 105px 0 0 105px;

			@media screen and (max-width: 1280px) {
				padding: 50px 20px 50px 60px;
			}
			@media screen and  (max-width: 768px) {
				order: -1;
				padding: 0;
				background-image: none;
			}
			p {
				max-width: 750px;
				padding: 15px 0;
			}
			.retreat-block__big {
				padding: 10px 0 20px;
				font-size: 45px;
				text-transform: uppercase;

				@media screen and (max-width: 1280px) {
					font-size: 40px;
				}
				@media screen and (max-width: 1080px) {
					padding-bottom: 10px;
					font-size: 30px;
				}
				@media screen and (max-width: 768px) {
					font-size: 25px;
				}
				@media screen and (max-width: 600px) {
					font-size: 20px;
				}
			}
		}
		&--right {
			margin-bottom: 0;

			@media screen and (min-width: 769px) {
				.retreat-block__photo {
					width: 100%;
					order: 2;
				}
				.retreat-block__text {
					width: 250%;
					max-width: 1040px;
					padding: 120px 20px 100px 160px;

					@media screen and (max-width: 1280px) {
						padding: 50px 20px 50px 60px;
					}
				}
				@media screen and (min-width: 1200px) {
					.retreat-block__big {
						margin-bottom: 20px;
					}
				}
			}
			@media screen and (max-width: 768px) {
				.retreat-block__photo {
					order: -2;
				}
			}
		}
	}
	&-text {
		position: relative;
		display: grid;
		grid-template-columns: 0.45fr 1fr;
		align-items: center;

		@media screen and  (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-row-gap: 20px;
		}
		p {
			padding: 15px 0;
		}
		&__orange {
			align-self: stretch;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 80px;
			background-image: url(/assets/images/retrit_block_bg.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left top;
			font-size: 25px;
			line-height: 1.2;
			border-radius: 105px 0 0 105px;

			@media screen and (max-width: 1280px) {
				padding: 40px;
				font-size: 22px;
			}
			@media screen and (max-width: 900px) {
				padding: 30px;
			}
			@media screen and  (max-width: 768px) {
				padding: 50px 30px;
			}
			@media screen and (max-width: 480px) {
				font-size: 18px;
			}
		}
		&__nobg {
			padding-left: 160px;

			@media screen and (max-width: 1280px) {
				padding-left: 60px;
			}
			@media screen and  (max-width: 768px) {
				padding: 0;
			}
			.retreat-text__big {
				font-size: 25px;
				text-transform: uppercase;

				@media screen and (max-width: 768px) {
					font-size: 20px;
				}
				@media screen and (max-width: 480px) {
					font-size: 18px;
				}
			}
		}
	}
	&-gray {
		padding: 80px 0;
		background-color: #efece9;

		@media screen and (max-width: 1199px) {
			padding: 50px 0;
		}
		@media screen and (max-width: 768px) {
			padding: 30px 0;
		}
	}
	&-bottom {
		padding-top: 40px;

		@media screen and (max-width: 1199px) {
			padding-top: 30px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
		}
	}
	&-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 1fr));
		grid-gap: 50px 100px;
		margin: 5px 0 120px;
		padding: 0;
		list-style: none;

		@media screen and (max-width: 1280px) {
			grid-gap: 50px;
		}
		@media screen and (max-width: 1199px) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: 991px) {
			grid-gap: 30px;
			margin-bottom: 50px;
		}
		@media screen and (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media screen and (max-width: 768px) {
			margin: 0 0 30px;
		}
		@media screen and (max-width: 600px) {
			grid-gap: 30px 15px;
		}
		@media screen and (max-width: 520px) {
			grid-template-columns: 1fr;
		}

		&-item {
			display: block;
			border-radius: 45px 45px 25px 25px;

			@media screen and (max-width: 991px) {
				padding-bottom: 25px;
			}

			&__photo {
				display: block;
				width: 100%;
				border-radius: 35px 0 35px 0;
			}
			&__name {
				display: block;
				padding: 25px 0 15px;
				text-transform: uppercase;
				font-size: 25px;
				line-height: 1.1;

				@media screen and (max-width: 991px) {
					font-size: 22px;
				}
				@media screen and (max-width: 1080px) {
					font-size: 20px;
				}
				@media screen and (max-width: 768px) and (min-width: 521px) {
					font-size: 18px;
				}
			}
			&__date {
				position: relative;
				display: block;
				margin-bottom: 15px;
				padding-left: 50px;
				font-size: 15px;
				font-family: $m;
				text-transform: uppercase;

				@media screen and (max-width: 1080px) and (min-width: 801px), (max-width: 768px) and (min-width: 521px) {
					font-size: 14px;
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					width: 36px;
					height: 36px;
					background-image: url(/assets/images/calendar_icon.svg);
					background-size: 36px;
					background-position: center;
				}
				span {
					display: block;
				}
			}
			&__lead {
				display: block;
				margin-bottom: 25px;
				font-size: 15px;
			}
			&__link {
				display: inline-block;
				padding: 15px 35px;
				font-size: 12px;
			}
		}
	}
}
.course-item {
	.webinar-item {
		&__grid {
			h1 {
				padding-bottom: 30px;
			}
		}
		&__text {
			@media screen and (min-width: 1200px) {
				margin-bottom: 60px;
			}
			&::after {
				background-image: linear-gradient(to top, rgb(255,255,255) 0%, rgb(255,248,233) 29%, rgb(255,147,5) 100%);
			}
			strong, b {
				font-size: 25px;

				@media (max-width: 768px) {
					font-size: 20px;
				}
			}
			@media screen and (min-width: 768px) {
				p:first-child {
					margin-bottom: 30px;
				}
			}
		}
	}
	&__form {
		padding-top: 100px;
		padding-bottom: 20px;

		@media (max-width: 1199px) {
			padding-top: 60px;
		}
		@media (max-width: 768px) {
			padding-top: 40px;
		}
	}
}
.course-reviews {
	padding-bottom: 40px;
	background-image: 
		url(/assets/images/wave.png),
		linear-gradient(to bottom, transparent 100px, #efece9 100px);
	background-repeat: no-repeat;
	background-position: center 140px;

	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}
	&__inner {
		position: relative;
		margin: 100px 0 0;
		padding: 0 40px;

		@media screen and (max-width: 1199px) {
			margin-top: 50px;
		}
		@media screen and (max-width: 768px) {
			margin-top: 30px;
			padding: 0 20px;
		}
		@media screen and (max-width: 480px) {
			padding: 0 10px;
		}
		.swiper-button-prev {
			left: -25px;

			@media screen and (max-width: 1600px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -25px;

			&::before {
				transform: rotate(90deg) scale(1,-1);
			}
			@media screen and (max-width: 1600px) {
				right: -15px;
			}
		}
		.swiper-pagination {
			position: static;
			margin: 30px 0 0;

			@media screen and (max-width: 768px) {
				margin-top: 20px;
			}
		}
		.swiper-slide {
			display: flex;
			flex-direction: column;
			height: auto;
			padding: 25px;
			box-sizing: border-box;

			&:nth-child(even) {
				.course-review {
					background-image: url(/assets/images/reviews_bg_2.jpg);
					background-position: right top;
				}
			}
			@media screen and (max-width: 800px) {
				padding: 10px;
			}
		}
	}
}
.course-review {
	flex-grow: 1;
	padding: 60px 20px 55px;
	border-radius: 35px;
	background-image: url(/assets/images/reviews_bg_1.jpg);
	background-position: left bottom;
	background-repeat: no-repeat;
  	background-color: rgb(255, 255, 255);
  	box-shadow: 2.121px 2.121px 25px 0px rgba(0, 0, 0, 0.25);

  	@media screen and (max-width: 991px) {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	@media screen and (max-width: 800px) {
		box-shadow: 2.121px 2.121px 10px 0px rgba(0, 0, 0, 0.25);
	}

  	&__name {
  		position: relative;
  		max-width: 520px;
  		margin: 0 auto;
  		padding: 0 0 60px;
  		text-align: center;
  		font-size: 25px;
  		text-transform: uppercase;

  		&::before {
  			content: '';
  			position: absolute;
  			left: 0;
  			bottom: 15px;
  			width: 68px;
  			height: 47px;
  			background-image: url(/assets/images/reviews_decor.png);
  			transform: scale(-1,-1);
  		}

  		@media screen and (max-width: 991px) {
			padding-bottom: 45px;

			&::before {
	  			bottom: 5px;
	  		}
		}
  	}
  	&__text {
  		position: relative;
  		max-width: 520px;
  		margin: 0 auto 50px;
  		padding-left: 35px;
  		padding-right: 15px;

  		@media screen and (max-width: 991px) {
			margin-bottom: 20px;
			padding-left: 15px;
		}
		@media screen and (max-width: 480px) {
			padding-right: 0;
			padding-left: 0;
		}

  		p {
  			padding: 20px 0 0;
  			font-size: 14px;
  		}
  		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: -120px;
			width: 68px;
			height: 47px;
			background-image: url(/assets/images/reviews_decor.png);

			@media screen and (max-width: 991px) {
				bottom: -125px;
			}
			@media screen and (max-width: 480px) {
				bottom: -130px;
			}
		}
  	}
  	&__ellipsis {
  		overflow: hidden;
	   	text-overflow: ellipsis;
	   	display: -webkit-box;
	   	-webkit-line-clamp: 11; /* number of lines to show */
	           line-clamp: 11;
	   	-webkit-box-orient: vertical;
  	}
  	&__btn {
  		position: relative;
  		display: block;
  		width: 195px;
  		margin: 0 auto;
  		padding: 22px;
  		text-align: center;
  		font-size: 11px;
  		text-transform: uppercase;
  		color: #a09890;
  		border-style: solid;
	  	border-width: 1px;
	  	border-color: rgb(198, 193, 189);
	  	border-radius: 30px;
	  	background: #fff;
	  	cursor: pointer;
	  	transition: 0.2s;

	  	@media screen and (max-width: 991px) {
			margin-bottom: 20px;
		}

	  	&::before {
		  	content: "";
		  	position: absolute;
		  	padding: 1px; 
		  	inset: 0;
		  	border-radius: 30px; 
		  	background: linear-gradient(to left, rgb(255,248,233) 0%, rgb(255,147,5) 100%);
		  	-webkit-mask: 
		     	linear-gradient(#fff 0 0) content-box, 
		     	linear-gradient(#fff 0 0);
		  	-webkit-mask-composite: xor;
		        mask-composite: exclude; 
		     opacity: 0;
		     transition: 0.2s;
		}
	  	&:hover {
	  		color: $orange;
	  		border-color: #fff;

	  		&::before {
				opacity: 1;
			}
	  	}
  	}
}
.course-popup {
	border-radius: 35px;
  	background-image: url(/assets/images/reviews_popup.jpg);

	.course-review {
		padding: 40px 20px 20px;
		border-radius: 0;
		background: none;
	  	box-shadow: none;

	  	@media screen and (max-width: 480px) {
			padding: 20px 0 0;
		}

	  	&__name {
	  		max-width: 1070px;
	  	}
	  	&__text {
	  		position: relative;
	  		max-width: 1070px;
	  		margin: 0;
	  		padding: 0 30px 60px;

	  		@media screen and (max-width: 680px) {
				padding: 0 15px 60px;
			}
			@media screen and (max-width: 480px) {
				padding: 0 0 60px;
			}
	  		&::after {
				bottom: 0;
			}
	  	}
	}
}