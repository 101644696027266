.authorization-page {
    background-image: url(/assets/images/login_bg.png);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: top;

    @media screen and (max-width: 520px) {
        background-image: none;
    }
}
.login-page {
    background-image: url(/assets/images/login_bg2.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: right 15%;

    @media screen and (max-width: 1240px) {
    background-size: 20% auto;
    }
    @media screen and (max-width: 520px) {
        background-image: none;
    }
}
.account-page {
    background-image: url(/assets/images/accoung_bg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: right top;

    @media screen and (max-width: 1240px) {
    background-size: 20% auto;
    }
    @media screen and (max-width: 520px) {
        background-image: none;
    }
}
.profile-page {
    background-image: url(/assets/images/profile_bg.jpg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: top;

    @media screen and (max-width: 520px) {
        background-image: none;
    }
}
.authorization {
    padding-bottom: 150px;

    @media screen and (max-width: 1199px) {
	    padding-bottom: 80px;
	}
    @media screen and (max-width: 768px) {
	    padding-bottom: 40px;
	}
    @media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
    &__tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        max-width: 1010px;
        margin: 40px auto 90px;

        @media screen and  (max-width: 1199px) {
            margin: 20px auto 70px;
        }
        @media screen and  (max-width: 768px) {
            grid-gap: 15px;
            margin: 0 auto 50px;
        }
        @media screen and  (max-width: 520px) {
            grid-gap: 5px;
        }
    }
    &__tab-item {
        padding: 35px;
        text-align: center;
        font-size: 36px;
        border-width: 1px;
        border-color: rgb(172, 172, 172);
        border-style: solid;
        border-radius: 35px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;

        @media screen and  (max-width: 768px) {
            padding: 20px;
            font-size: 25px;
        }
        @media screen and  (max-width: 520px) {
            padding: 15px;
            font-size: 20px;
        }

        &:not(.active):hover {
            box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
        }

        &.active {
            background-image: $gradient;
            border: none;
        }
    }
}
.profile-form {
    grid-template-columns: 1fr;
    max-width: 355px;
    margin: 0 auto;

    &__link {
        display: block;
        margin-top: 10px;
        font-size: 13px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
    &--login {
        .form__check {
            max-width: 180px;
            margin: 35px auto -5px;

            @media screen and  (max-width: 480px) {
                margin: 10px auto 0;
            }
        }
        .form__btn {
            max-width: 290px;
            width: 100%;
        }
    }
    &--registration {
        @media screen and  (min-width: 681px) {
            grid-gap: 25px;
        }
        .form__check {
            margin-top: 20px;

            label {
                padding-left: 35px;
                min-height: 17px;
          
                &::before {
                  width: 17px;
                  height: 17px;
                  background-size: 11px;
                }
                a {
                    color: $orange;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .form__btn {
            max-width: 290px;
            width: 100%;
            margin-top: 15px;
        }
        // .form__field--date::before {
        //     content: 'ДАТА РОЖДЕНИЯ';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     display: block;
        //     width: 100%;
        //     padding: 12px 0;
        //     font-size: 13px;
        //     line-height: 1.15;
        //     border: none;
        //     border-bottom: 1px solid #000;
        //     background: #fff;
        //     transition: 0.2s;
        //     z-index: 2;
        //     cursor: text;
        // }
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }
    &--recovery {
        max-width: 490px;

        .form__btn {
            max-width: 230px;
            width: 100%;
            margin-top: 15px;
        }
    }
    &__result {
        max-width: 355px;
        margin: 20px auto 0;
        color: red;
        text-align: center;

        &.success {
            color: green;
        }
    }
    &--edit {
        grid-gap: 20px;
        max-width: 490px;

        input[readonly] {
            border-bottom-color: #fff;
        }

        .form__field {
            label {
                display: block;
                margin-bottom: 0;
                font-family: $m;
                font-weight: 500;
            }
            &--row {
                display: grid;
                grid-template-columns: 170px 1fr;
                grid-column-gap: 20px;
                align-items: center;

                label,
                .form__error {
                    grid-column: 1/-1;
                }
                button {
                    padding: 0;
                    font-size: 13px;
                    text-decoration: underline;
                    color: $orange;
                    background: none;
                    border: none;
                    text-align: left;
                    cursor: pointer;
                    transition: 0.15s;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .form__check {
            margin-top: 15px;

            label {
                padding-left: 35px;
                min-height: 17px;
          
                &::before {
                  width: 17px;
                  height: 17px;
                  background-size: 11px;
                }
                a {
                    color: $orange;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .form__btn {
            max-width: 285px;
            width: 100%;
            margin-top: 30px;
        }
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
        select,
        .select__control {
            width: 130px;
            margin-top: 5px;
            padding: 12px 5px 12px 20px;
            font-size: 13px;
            border-width: 1px;
            border-color: rgb(172, 172, 172);
            border-style: solid;
            border-radius: 0;
            background-color: rgb(255, 255, 255);
            box-shadow: none;

            &:hover {
                border-color: rgb(172, 172, 172);
            }
            &--is-focused,
            &--is-focused:hover {
                border-color: $orange;
            }

            .select__value-container,
            .select__input-container  {
                margin: 0;
                padding: 0;
            }
            .select__indicator-separator {
                display: none;
            }
            .select__indicator {
                padding: 0;
            }
            
        }
        .select__menu {
            width: 130px;
            font-size: 13px;
        }
    }
}
.account {
    padding-top: 50px;
    padding-bottom: 150px;

    @media screen and (max-width: 1199px) {
        padding-top: 30px;
	    padding-bottom: 80px;
	}
    @media screen and (max-width: 768px) {
        padding-top: 0;
	    padding-bottom: 40px;
	}
    &__content {
        display: grid;
        grid-template-columns: 245px 1fr;
        grid-gap: 30px;
        margin-top: 10px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 150px 1fr;
        }
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            grid-gap: 30px;
        }
    }
    &__menu {
        @media screen and (max-width: 600px) {
           display: grid;
           grid-template-columns: 1fr 1fr;
           grid-gap: 15px;
           align-items: center;
           padding: 15px;
           text-align: center;
           border: 1px solid rgb(172, 172, 172);
        }
        a, button {
            display: block;
            margin-bottom: 30px;
            padding: 0;
            font-size: 18px;
            font-family: $m;
            font-weight: 500;
            border: none;
            background: none;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                font-size: 17px;
            }
            @media screen and (max-width: 600px) {
                margin-bottom: 0;
            }
            @media screen and (max-width: 400px) {
                font-size: 16px;
            }
            &.active,
            &:hover {
                color: $orange;
            }
        }
    }
}
.recovery {
    &__title {
        padding: 25px 0 55px;
        text-align: center;
        font-size: 25px;

        @media screen and (max-width: 520px) {
            font-size: 20px;
        }
    }
}
.welcome {
    &__title {
        padding: 25px 0 15px;
        text-align: center;
        font-size: 25px;

        @media screen and (max-width: 520px) {
            font-size: 20px;
        }
    }
    &__text {
        padding: 0 0 45px;
        text-align: center;

        span {
            display: block;
            margin-top: 10px;
        }
    }
    &__btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 90px;
        max-width: 1070px;
        margin: 0 auto;

        @media screen and  (max-width: 1199px) {
            grid-gap: 50px;
        }
        @media screen and  (max-width: 768px) {
            grid-gap: 15px;
        }
        @media screen and  (max-width: 520px) {
            grid-template-columns: 1fr;
        }
        &-item {
            position: relative;
            display: flex;
            align-items: center;
            height: 165px;
            padding: 30px;
            padding-left: 160px;
            border-style: solid;
            border-width: 1px;
            border-color: rgb(255, 151, 12);
            border-radius: 35px;
            background-color: rgb(255, 255, 255);
            transition: 0.2s;

            @media screen and  (max-width: 768px) {
                height: 120px;
                padding-left: 120px;
            }
            @media screen and  (max-width: 520px) {
                padding: 20px;
                padding-left: 120px;
            }

            &:hover {
                border: none;
                background-image: url(/assets/images/welcome_btn_bg.jpg);
                background-repeat: no-repeat;
                background-position: right top;
                box-shadow: 0px 3px 25px 0px rgba(35, 35, 35, 0.25);
            }
            &::before {
                content: '';
                position: absolute;
                left: 40px;
                top: 50%;
                transform: translateY(-50%);
                width: 80px;
                height: 84px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 84px;

                @media screen and  (max-width: 520px) {
                    left: 20px;
                }
            }
            &--orders::before {
                background-image: url(/assets/images/welcome_btn_1.svg);
            }
            &--profile::before {
                background-image: url(/assets/images/welcome_btn_2.svg);
            }
            span {
                max-width: 215px;
            }
        }
    }
}
.personal-data {
    max-width: 490px;
    margin-left: 260px;

    @media screen and (max-width: 1480px) {
       margin: 0 auto;
    }

    &__title {
        padding: 15px 0 40px;
        font-size: 25px;
        text-align: left;
        text-transform: none;

        @media screen and (max-width: 768px) {
            padding-bottom: 30px;
        }
        @media screen and (max-width: 600px) {
            text-align: center;
        }
        @media screen and (max-width: 520px) {
            font-size: 20px;
        }
    }
}
.orders {
    &__title {
        padding: 0 0 25px;
        font-size: 20px;
        text-align: left;
        text-transform: none;

        @media screen and (max-width: 600px) {
            text-align: center;
        }
    }
    &__list {
        width: 100%;
        border-collapse: collapse;

        td, th {
            padding: 11px 0;
            border: none;
            border-bottom: 1px solid #acacac;

            &:nth-child(3) {
                width: 140px;
            }
        }
        th {
            padding: 18px 0;
            font-size: 18px;
            font-weight: 400;
            text-align: left;
        }
        td {
            font-family: $m;
            font-size: 14px;
        }
    }
    &__item-status {
        display: block;
        width: 115px;
        padding: 11px 5px;
        text-align: center;
        color: #fff;
        font-size: 13px;
        border: none;
        border-radius: 20px;
        background-color: #57c557;

        &.ended {
            background-color: $orange;
        }
    }
}
.support {
    max-width: 1040px;

    &__title {
        padding: 0 0 20px;
        font-size: 20px;
        text-align: left;

        @media screen and (max-width: 600px) {
            text-align: center;
        }
    }
    .about-form {
        margin-top: 65px;
        margin-bottom: 0;

        @media screen and (max-width: 600px) {
            margin-top: 30px;
        }
    }
    .modal.form_result .about-form {
        margin-top: 0;
    }
}
.order-item {
    max-width: 1040px;

    &__title {
        padding: 0 0 40px;
        font-size: 20px;
        text-align: left;

        &--booking {
            padding-bottom: 25px;
        }

        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
        @media screen and (max-width: 600px) {
            text-align: center;
        }
    }
    &__video {
        position: relative;
        margin-bottom: 35px;
		// padding-bottom: 56.25%; /* 16:9 */
		// height: 0;

        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }

        // iframe {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        // }
        img {
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        &-btn {
            position: absolute;
            z-index: 3;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 87px;
            height: 87px;
            margin-bottom: 20px;
            border-radius: 50%;
            border: none;
            background-color: rgb(26, 26, 24);
            background-image: url(/assets/images/arrow.png);
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.2;
            cursor: pointer;

            &:hover{
                opacity: 1;
            }
            @media screen and (max-width: 768px) {
                width: 50px;
                height: 50px;
                margin-top: 50px;
                margin-bottom: 15px;
                background-size: 20px;
            }
        }
    }
    &__text {
        padding: 10px 20px;
        font-size: 14px;
        background-color: #f7f7f7;

        p {
            padding: 12px 0;
        }
    }
    &__back {
        display: block;
        width: 195px;
        margin-top: 50px;
        margin-left: auto;
        padding: 17px;
        font-size: 12px;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
        }
    }
    .about-form {
        margin: 80px 0 0;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
        }
    }
}