.test {
	padding-bottom: 70px;

	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
}
.test-list {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	grid-gap: 70px 120px;
	margin: 5px 0 70px;
	padding: 0;
	list-style: none;

	@media screen and (max-width: 1480px) {
	    grid-gap: 70px;
	}
	@media screen and (max-width: 1280px) {
	    grid-gap: 50px;
	}
	@media screen and (max-width: 1199px) {
	    margin-bottom: 50px;
	}
	@media screen and (max-width: 980px) {
	   	grid-gap: 40px 30px;
	}
	@media (max-width: 768px) {
		margin: 0 0 20px;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	li {
		position: relative;
		display: flex;
		flex-direction: column;

		&::before {
			content: '';
			position: absolute;
			top: 24%;
			left: 8.5%;
			width: 91.5%;
			height: 76%;
			background-color: #efece9;

			@media screen and (max-width: 480px) {
			    top: 15%;
				left: 5%;
				width: 95%;
				height: 85%;
			}
		}
	}
}
.test-list-item {
	position: relative;
	z-index: 1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	&__text {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 91.5%;
		min-height: 270px;
		margin-left: auto;
		padding: 30px 30px 45px 70px; 

		@media screen and (max-width: 1280px) {
		   padding: 20px 30px 30px;
		}
		@media (max-width: 768px) {
			min-height: 0;
		}
		@media screen and (max-width: 480px) {
			width: 95%;
		}
	}
	&__photo {	
		width: 60%;	
		border-radius: 25px 0 25px 0;

		@media screen and (max-width: 520px) {
			width: 90%;	
		}
	}
	&__name {
		margin-bottom: 20px;
		line-height: 1.2;
		font-size: 30px;
		text-transform: uppercase;

		@media (max-width: 1480px) {
			font-size: 27px;
		}
		@media screen and (max-width: 1280px) {
		   font-size: 25px;
		}
		@media (max-width: 1080px) {
			margin-bottom: 15px;
		}
		@media (max-width: 980px) {
			font-size: 20px;
		}
		@media (max-width: 680px) {
			font-size: 18px;
		}
	}
	&__link {
		margin-top: auto;
   		padding: 15px 40px;
   		text-align: center;
   		font-size: 12px;

		&:hover {
			box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
		}
	}
}
.test-item-page {
	background-image: 
		url(/assets/images/test_btm_bg.jpg),
		url(/assets/images/news_item_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 
		right bottom,
		center top;

	@media screen and (min-width: 1921px) {
		background-image: 
			url(/assets/images/test_btm_bg.jpg);
		background-position: 
			right bottom;
	}		
	@media screen and (max-width: 1480px) {
	    background-size: 
	    	50% auto,
	    	100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.test-item {
	padding-bottom: 65px;

	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	&__text {
		max-width: 1270px;
		margin: 0 auto 50px;

		@media (max-width: 768px) {
			margin-bottom: 30px;
		}
		ul {
			list-style: none;
			padding-left: 0;

			li {
				position: relative;
				display: block;
				margin-bottom: 10px;

				&::before {
					content: '';
					position: relative;
					top: 4px;
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 15px;
					background-image: url(/assets/images/check.svg);
					background-repeat: no-repeat;
					background-size: 20px;
					filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
				}
			}
		}
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 1fr));
		justify-content: space-between;
		grid-gap: 60px 30px;
		max-width: 1270px;
		margin: 0 auto 85px;
		padding: 0;
		list-style: none;

		@media screen and (max-width: 1280px) {
		    grid-gap: 30px;
		}
		@media screen and (max-width: 1199px) {
		    margin-bottom: 50px;
		}
		@media (max-width: 520px) {
			grid-template-columns: repeat(2,1fr);
		}
		@media (max-width: 768px) {
			margin-bottom: 40px;
		}
	}
	&__variant {
		cursor: pointer;
		img {
			border-radius: 25px;
			transition: 0.3s;
			transform-origin: center;
		}
		&-name {
			display: block;
			margin: 27px 0 0; 
			position: relative;
	      	padding-left: 50px;
	      	font-size: 20px;
	      	line-height: 1.2;

	      	@media screen and (max-width: 680px) {
	      		margin-top: 15px;
			    font-size: 18px;
			}

		    &::before {
		        content: '';
		        position: absolute;
		        left: 0;
		        top: -3px;
		        width: 33px;
		        height: 33px;
		        border-style: solid;
		        border-width: 1px;
		        border-color: #000;
		        background-repeat: no-repeat;
		        background-position: center;
		        background-size: 22px;
		        transition: 0.2s;

		        @media screen and (max-width: 680px) {
		      		top: -5px;
				}
		     }
		}

	}
	input:checked + .test-item__variant {
		img {
		  	border-style: solid;
		  	border-width: 2px;
		  	border-color: rgb(255, 255, 255);
		  	box-shadow: 0px 0px 29.05px 5.95px rgba(255, 143, 10, 1);
		}
		.test-item__variant-name {
			color: $orange;

			&::before {
		      	background-image: url(/assets/images/checked.svg);
		      	filter: invert(54%) sepia(86%) saturate(1380%) hue-rotate(357deg) brightness(104%) contrast(106%);
	    	}
	    }
    }
    .test-item__variant:hover {
		img {
		  	box-shadow: 0px 0px 29.05px 5.95px rgba(255, 143, 10, 1);
		}
	}
	&__submit {
		display: block;
		width: 230px;
		max-width: 100%;
		margin: 0 auto;
		padding: 20px;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.2);
		}
	}
}
.test-popup {
	&__text {
		position: relative;
		padding: 60px 0 80px;

		@media screen and (max-width: 980px) {
      		padding: 40px 0 50px;
		}
		@media screen and (max-width: 768px) {
      		padding: 20px 0 40px;
		}
		@media screen and (max-width: 480px) {
      		padding-bottom: 30px;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 113px;
	  		height: 1px;
			background-color: #000000;
		}

		p {
			padding: 12px 0;
		}
		.test-popup__title {
			padding: 0 0 25px;
			font-size: 30px;
			text-align: center;
			text-transform: uppercase;

			@media screen and (max-width: 768px) {
				font-size: 25px;
			}
			@media screen and (max-width: 480px) {
				padding-bottom: 15px;
				font-size: 22px;
			}
		}
	}
	.about-form {
		max-width: 810px;
		margin: 0 auto 50px;
		padding: 0;
		background-image: none;

		@media screen and (max-width: 480px) {
			margin-bottom: 0;
		}

		&__grid {
			grid-template-columns: 1fr 1.3fr;
			grid-row-gap: 20px;

			@media screen and (max-width: 440px) {
				grid-template-columns: 1fr;
			}
		}
		&__title {
			padding: 45px 0 30px;
			text-transform: uppercase;
			text-align: center;
			font-size: 30px;

			@media screen and (max-width: 768px) {
				padding: 30px 0;
			}
			@media screen and (max-width: 520px) {
				padding: 15px 0 20px;
				font-size: 22px;
			}
		}
		.form__check {
			align-self: center;
		    grid-column: auto;
		    margin-right: -180px;

		    @media screen and (max-width: 768px) {
	      		margin-right: -30px;
			}
			@media screen and (min-width: 521px) {
				margin-top: 10px;
			}
			@media screen and (max-width: 520px) {
				margin-right: 0;
		  }

		    label {
		    	font-size: 11px;
		    	letter-spacing: 0;
		    }
		}
		.form__btn {
		    grid-column: auto;
		    margin-left: auto;
		    margin-right: 0;
		    padding: 20px 45px;

		    @media screen and (min-width: 521px) {
				margin-top: 10px;
			}
			@media screen and (max-width: 440px) {
				margin-right: auto;
			}
		}
	}
}
