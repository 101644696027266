.group {
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	.team-item-about {
		margin: 10px 0 40px;

		@media screen and  (max-width: 800px) {
			grid-template-columns: 1fr;
			margin-bottom: 30px;

			&__photo {
				margin: 0 auto;
			}
			&__block {
				order: -1;
				padding: 10px 20px 30px;
				background-image: none;
			}
		}
		&__block {
			padding-top: 40px;

			@media screen and (max-width: 1199px) {
				padding-top: 25px;
			}
			@media screen and (max-width: 900px) {
				padding-top: 0;
			}
		}
		
		&__smtitle {
			padding: 40px 0;
			font-size: 25px;

			@media screen and (max-width: 1199px) {
				padding: 20px 0;
				font-size: 20px;
			}
			@media screen and (max-width: 900px) {
				padding: 0 0 15px;
			}
			@media screen and (max-width: 480px) {
				font-size: 18px;
			}
		}
		@media screen and  (min-width: 901px) {
			p {
				max-width: 750px;
			}
		}
	}
	&-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 1fr));
		grid-gap: 50px 100px;
		margin: -10px 0 120px;
		padding: 0;
		text-align: center;
		list-style: none;

		@media screen and (max-width: 1280px) {
			grid-gap: 50px;
		}
		@media screen and (max-width: 1199px) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: 991px) {
			grid-gap: 30px;
			margin-bottom: 50px;
		}
		@media screen and (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media screen and (max-width: 768px) {
			margin: 0 0 30px;
		}
		@media screen and (max-width: 600px) {
			grid-gap: 30px 15px;
		}
		@media screen and (max-width: 420px) {
			grid-template-columns: 1fr;
		}
		li {
			display: flex;
			flex-direction: column;
		}
		&-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-grow: 1;
			padding-bottom: 40px;
			border-radius: 45px 45px 25px 25px;
  			background-color: rgb(239, 236, 233);
  			transition: 0.15s;

  			&:hover {
  				background-color: #f9f6f3;
  			}

			@media screen and (max-width: 991px) {
				padding-bottom: 25px;
			}

			&__photo {
				display: block;
				width: 100%;
				border-radius: 45px;
			}
			&__name {
				display: block;
				padding: 25px 15px;
				text-transform: uppercase;
				font-size: 25px;
				line-height: 1.1;

				@media screen and (max-width: 991px) {
					font-size: 22px;
				}
				@media screen and (max-width: 900px) {
					font-size: 20px;
				}
				@media screen and (max-width: 520px) and (min-width: 481px) {
					font-size: 18px;
				}
				@media screen and (max-width: 480px) and (min-width: 421px) {
					font-size: 16px;
				}
			}
			&__more {
				display: inline-block;
				margin: 0 15px;
				padding: 15px 35px;
				font-size: 12px;
			}
		}
	}
}