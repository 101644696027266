.services {
	padding-bottom: 120px;
	background-image: linear-gradient(to bottom, rgb(255,255,255) 0%, rgb(239,236,233) 46%, rgb(255,255,255) 100%);
	background-size: 100% 960px;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (max-width: 980px) {
		padding-bottom: 60px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	.main-services-list {
	    grid-row-gap: 110px;
	    padding: 60px 0 0;
	    text-align: center;

	    @media screen and (max-width: 1280px) {
			padding-top: 30px;
		}
	    @media screen and (max-width: 1170px) {
			grid-row-gap: 50px;
		}
		@media screen and (max-width: 768px) {
			grid-row-gap: 30px;
			padding-top: 0;
		}
	}
	.main-services-list__item {
		text-align: left;
	}
	.services__item-link {
		display: inline-block;
		margin-top: 40px;
		padding: 15px 35px;
		font-size: 12px;
		
		@media screen and (max-width: 980px) {
			margin-top: 20px;
		}
		@media screen and (max-width: 480px) {
			margin-top: 15px;
			padding: 15px 20px;
		}
	}
}