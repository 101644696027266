.video-page {
	background-image: url(/assets/images/video_bg.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center top;

	@media screen and (max-width: 768px) {
	    background-image: none;
	}
}
.video {
	padding-bottom: 125px;

	@media (max-width: 1199px) {
		padding-bottom: 80px;
	}
	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
}
.video-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 80px;
	grid-row-gap: 145px;
	margin: 30px 0 0;
	padding: 0;
	list-style: none;

	@media (max-width: 1480px) {
		grid-row-gap: 80px;
	}
	@media (max-width: 1199px) {
		grid-gap: 50px;
	}
	@media (max-width: 768px) {
		grid-gap: 30px;
		margin-top: 0;
	}
	@media (max-width: 520px) {
		grid-template-columns: 1fr;
	}
}
.video-item {
	&__content {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	img {
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
	&__btn {
		position: absolute;
		z-index: 3;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: block;
		width: 87px;
  		height: 87px;
  		margin-bottom: 20px;
		border-radius: 50%;
		border: none;
  		background-color: rgb(26, 26, 24);
  		background-image: url(/assets/images/arrow.png);
  		background-repeat: no-repeat;
  		background-position: center;
  		opacity: 0.2;
  		cursor: pointer;

  		&:hover{
  			opacity: 1;
  		}
  		@media screen and (max-width: 768px) {
			width: 50px;
	  		height: 50px;
	  		margin-top: 50px;
	  		margin-bottom: 15px;
	  		background-size: 20px;
		}
	}
	&__name {
		display: block;
		margin: 20px 0 0;
		font-size: 25px;
		text-transform: uppercase;

		@media (max-width: 980px) {
			font-size: 20px;
		}
		@media (max-width: 768px) {
			margin-top: 10px;
			font-size: 18px;
		}
		@media (max-width: 600px) {
			font-size: 16px;
		}
	}
}