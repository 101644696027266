.popup {
	width: 100%;
	max-width: 1270px;
	max-height: 97vh;
	padding: 25px;
	background: #fff;
	border-radius: 70px 0 0 70px;
  	background-color: rgb(255, 255, 255);
  	background-image: url(/assets/images/test_result_bg.jpg);
  	background-position: center;
  	background-size: cover;
	overflow: auto;

	&__content {
		max-width: 1010px;
		margin: 0 auto;
	}
	.popup__title {
		padding: 25px 0 20px;
		font-size: 32px;
		font-weight: 500;
		line-height: 1.2;
		text-align: center;

		@media screen and (max-width: 480px) {
			padding-top: 0;
			padding-bottom: 10px;
			font-size: 25px;
		}
	}
	.popup__text {
		max-width: 335px;
		margin: 0 auto;
		padding: 0 0 35px;
		font-size: 16px;
		text-align: center;

		@media screen and (max-width: 480px) {
			padding-bottom: 15px;
		}
	}
}
.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	outline: none;
	position: fixed;
	-webkit-tap-highlight-color: transparent;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background: rgba(#1e1e1e, 0.87);
	transition-timing-function: cubic-bezier(.22,.61,.36,1);
	text-align: center;
	overflow: initial;
	z-index: 99992;

	&.modal_form {
		.about-form {
			width: 1040px;
			max-width: 100%;
			border-radius: 90px 0 0 90px;

			@media screen and  (min-width: 769px) {
				padding-top: 30px;
			}
		}
		@media screen and (min-width: 481px) {
			.modal__close {
				right: 30px;
				top: 30px;
			}
		}
	}
	&.form_result {
		.about-form {
			width: 600px;
			max-width: 100%;
			border-radius: 90px 0 0 90px;

			@media screen and  (min-width: 769px) {
				padding-top: 30px;
			}
		}
		@media screen and (min-width: 481px) {
			.modal__close {
				right: 30px;
				top: 30px;
			}
		}
	}
	&.schedule__popup {
		.modal__inner {
			width: 890px;
			max-width: 95%;
			padding: 30px 15px 37px;
			text-align: left;
			background-color: #fff;
			background-image: url(/assets/images/schedule_popup1.jpg);
			background-size: cover;
			background-position: center;
			border-radius: 30px;

			@media screen and  (min-width: 480px) {
				padding: 30px 15px;
			}
		}
		.form_result, 
		.modal_form {
			.modal__inner {
				width: auto;
				padding: 0;
				background: none;
				border-radius: 0;
			}
		}
		.schedule__title {
			padding-top: 0;
			padding-bottom: 5px;
		}
		.schedule__text {
			padding: 0 0 20px;
		}
		.schedule__more {
			display: none;
		}
		.schedule__submit {
			margin-top: 65px;

			@media screen and  (min-width: 680px) {
				margin-top: 30px;
			}	
		}
		.modal__close {
			right: 10px;
			top: 10px;
		}
	}
}
.modal__inner {
	position: relative;
	display: inline-block;
	overflow: auto;
}
.modal__close {
	background: transparent;
	border: 0;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	opacity: .8;
	width: 45px;
	height: 45px;
	padding: 0;
	position: absolute;
	right: 40px;
	top:40px;
	z-index: 401;

	@media screen and (max-width: 480px) {
		right: 10px;
		top: 10px;
		width: 40px;
		height: 40px;
	}
  
	&:hover {
	  opacity: 1;
	  color: currentColor;
	}
	svg {
		display: block;
		height: 100%;
		overflow: visible;
		position: relative;
		width: 100%;
		transform: rotate(45deg);
	}
}
