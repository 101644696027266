.education-group-page {
	background-image: url(/assets/images/ed_group_bg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: left top;

	@media screen and (max-width: 1480px) {
	    background-size: 50% auto;
	}
	@media screen and (max-width: 1199px) {
		background-position: left 10%;
		background-size: 30% auto;
	}
	@media screen and (max-width: 768px) {
	    background-image: none;
	}
}
.education-group {
    @media screen and (min-width: 1200px) {
	    h1 {
			padding-top: 30px;
		}
	}
	.team-item-about {
		margin-bottom: 60px;

        &__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 20px;
            padding-top: 20px;
        }
		@media screen and (max-width: 900px) {
			grid-template-columns: 1fr;
			margin-bottom: 30px;

			&__photo {
				margin: 0 auto;
			}
			&__block {
				order: -1;
				padding: 0 0 30px;
				background-image: none;
			}
		}
	    p {
			max-width: 750px;
		}
        .check-list {
            max-width: 750px;
            margin: 15px 0 20px;
            padding: 0;

            li {
                margin-bottom: 25px;
                padding-left: 33px;

                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    margin-right: 0;
                }
            }
        }
	}
	.webinars-list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        max-width: 1010px;
		margin: 30px auto 80px;

        @media screen and (max-width: 1199px) {
            margin-bottom: 50px;
        }
        @media (max-width: 768px) {
            margin: 0 0 40px;
        }
        @media screen and (max-width: 480px) {
            grid-template-columns: 1fr;
        }
        li::before {
            left: 0;
            width: 100%;
        }
        &-item {
            padding: 0 35px;

            @media screen and (max-width: 900px) {
                padding: 0 15px;
            }
        }
        &-item__photo {
            width: 100%;
            border-radius: 30px 0 30px 0;
        }
        &-item__text {
            width: 100%;
            padding: 30px 20px 45px;

            @media screen and (max-width: 900px) {
                padding: 20px 0 30px;
            }
        }
        &-item__link {
            justify-content: space-between;
        }
	}
}