.retreat-page {
	background-image: 
		url(/assets/images/contacts_bg.jpg),
		url(/assets/images/retreat_bg_btm.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 
		left top,
		left bottom;

	@media screen and (max-width: 1480px) {
	    background-size: 100% auto;
	}
	@media screen and (max-width: 1199px) {
	    background-image: none;
	}
}
.retreat {
	padding-bottom: 140px;

	@media (max-width: 1199px) {
		padding-bottom: 80px;
	}
	@media (max-width: 768px) {
		padding-bottom: 30px;
	}
	@media screen and (min-width: 1200px) {
	    h1 {
			padding-bottom: 45px;
			padding-top: 30px;
		}
	}
	&-block {
		position: relative;
		display: grid;
		grid-template-columns: 0.45fr 1fr;
		align-items: center;
		margin-bottom: 140px;

		@media screen and (max-width: 1280px) {
			margin-bottom: 90px;
		}
		@media screen and (max-width: 1080px) {
			margin-bottom: 50px;
		}
		@media screen and  (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-row-gap: 20px;
			margin-bottom: 30px;
		}
		@media screen and  (min-width: 769px) {
			&__photo {
				width: 250%;
				max-width: 1140px;
				order: -1;
				position: relative;
				z-index: 1;
			}
		}
		&__text {
			position: relative;
			z-index: 2;
			padding: 90px 20px 90px 160px;
			background-image: url(/assets/images/ep_2.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left bottom;
			border-radius: 105px 0 0 105px;

			@media screen and (max-width: 1280px) {
				padding: 50px 20px 50px 60px;
			}
			@media screen and  (max-width: 768px) {
				order: -1;
				padding: 0;
				background-image: none;
			}
			p {
				max-width: 750px;
				padding: 15px 0;
			}
			.retreat-block__big {
				padding: 10px 0 20px;
				font-size: 45px;
				text-transform: uppercase;

				@media screen and (max-width: 1280px) {
					font-size: 40px;
				}
				@media screen and (max-width: 1080px) {
					padding-bottom: 10px;
					font-size: 30px;
				}
				@media screen and (max-width: 768px) {
					font-size: 25px;
				}
				@media screen and (max-width: 600px) {
					font-size: 20px;
				}
			}
		}
		&--right {
			margin-bottom: 0;

			@media screen and (min-width: 769px) {
				.retreat-block__photo {
					width: 100%;
					order: 2;
				}
				.retreat-block__text {
					width: 250%;
					max-width: 1040px;
					padding: 120px 20px 100px 160px;

					@media screen and (max-width: 1280px) {
						padding: 50px 20px 50px 60px;
					}
				}
				@media screen and (min-width: 1200px) {
					.retreat-block__big {
						margin-bottom: 20px;
					}
				}
			}
			@media screen and (max-width: 768px) {
				.retreat-block__photo {
					order: -2;
				}
			}
		}
	}
	&-text {
		position: relative;
		display: grid;
		grid-template-columns: 0.45fr 1fr;
		align-items: center;

		@media screen and  (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-row-gap: 20px;
		}
		p {
			padding: 15px 0;
		}
		&__orange {
			align-self: stretch;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 80px;
			background-image: url(/assets/images/retrit_block_bg.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left top;
			font-size: 25px;
			line-height: 1.2;
			border-radius: 105px 0 0 105px;

			@media screen and (max-width: 1280px) {
				padding: 40px;
				font-size: 22px;
			}
			@media screen and (max-width: 900px) {
				padding: 30px;
			}
			@media screen and  (max-width: 768px) {
				padding: 50px 30px;
			}
			@media screen and (max-width: 480px) {
				font-size: 18px;
			}
		}
		&__nobg {
			padding-left: 160px;

			@media screen and (max-width: 1280px) {
				padding-left: 60px;
			}
			@media screen and  (max-width: 768px) {
				padding: 0;
			}
			.retreat-text__big {
				font-size: 25px;
				text-transform: uppercase;

				@media screen and (max-width: 768px) {
					font-size: 20px;
				}
				@media screen and (max-width: 480px) {
					font-size: 18px;
				}
			}
		}
	}
	&-gray {
		padding: 80px 0;
		background-color: #efece9;

		@media screen and (max-width: 1199px) {
			padding: 50px 0;
		}
		@media screen and (max-width: 768px) {
			padding: 30px 0;
		}
	}
	&-bottom {
		padding-top: 40px;

		@media screen and (max-width: 1199px) {
			padding-top: 30px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
		}
	}
	&-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 1fr));
		grid-gap: 50px 100px;
		margin: 5px 0 120px;
		padding: 0;
		list-style: none;

		@media screen and (max-width: 1280px) {
			grid-gap: 50px;
		}
		@media screen and (max-width: 1199px) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: 991px) {
			grid-gap: 30px;
			margin-bottom: 50px;
		}
		@media screen and (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media screen and (max-width: 768px) {
			margin: 0 0 30px;
		}
		@media screen and (max-width: 600px) {
			grid-gap: 30px 15px;
		}
		@media screen and (max-width: 520px) {
			grid-template-columns: 1fr;
		}

		&-item {
			display: block;
			border-radius: 45px 45px 25px 25px;

			@media screen and (max-width: 991px) {
				padding-bottom: 25px;
			}

			&__photo {
				display: block;
				width: 100%;
				border-radius: 35px 0 35px 0;
			}
			&__name {
				display: block;
				padding: 25px 0 15px;
				text-transform: uppercase;
				font-size: 25px;
				line-height: 1.1;

				@media screen and (max-width: 991px) {
					font-size: 22px;
				}
				@media screen and (max-width: 1080px) {
					font-size: 20px;
				}
				@media screen and (max-width: 768px) and (min-width: 521px) {
					font-size: 18px;
				}
			}
			&__date {
				position: relative;
				display: block;
				margin-bottom: 15px;
				padding-left: 50px;
				font-size: 15px;
				font-family: $m;
				text-transform: uppercase;

				@media screen and (max-width: 1080px) and (min-width: 801px), (max-width: 768px) and (min-width: 521px) {
					font-size: 14px;
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					width: 36px;
					height: 36px;
					background-image: url(/assets/images/calendar_icon.svg);
					background-size: 36px;
					background-position: center;
				}
				span {
					display: block;
				}
			}
			&__lead {
				display: block;
				margin-bottom: 25px;
				font-size: 15px;
			}
			&__link {
				display: inline-block;
				padding: 15px 35px;
				font-size: 12px;
			}
		}
	}
}
.retreat-item {
	&__date {
		padding: 25px 0 0;
		text-align: center;
		text-transform: uppercase;
		font-size: 20px;
		font-family: $m;

		@media screen and (max-width: 768px) {
			font-size: 18px;
		}
		@media screen and (max-width: 480px) {
			font-size: 17px;
		}

		span {
			display: block;
		}
	}
	&__slider {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		margin: 90px 0 50px;

		@media screen and (max-width: 1199px) {
			margin: 50px 0;
		}
		@media screen and (max-width: 768px) {
			margin: 30px 0;
		}

		.swiper-pagination {
			position: static;
			margin: 35px 0 0;
			text-align: center;

			@media screen and (max-width: 768px) {
				margin-top: 10px;
			}
		}
		.swiper-button-prev {
			left: 24%;
		}
		.swiper-button-next {
			right: 24%;

			&::before {
				transform: rotate(90deg) scale(1,-1);
			}
		}
	}
}