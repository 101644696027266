.faq-page {
  background-image: url(/assets/images/news_item_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left -50px;

  @media screen and (max-width: 1920px) {
    background-size: auto;
  }
  @media screen and (max-width: 1480px) {
    background-size: 100% auto;
  }
  @media screen and (max-width: 900px) {
    background-image: none;
  }
}
.faq {
  position: relative;
  padding-bottom: 70px; 

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      padding-top: 30px;
    }
  }
  .about-form {
    margin-bottom: 0;
  }
}
  .faq-list {
    max-width: 1025px;
    margin: 0 auto 85px; 

    @media screen and (max-width: 768px) {
       margin-bottom: 30px;
    }
  }

.faq-list__item {
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
     margin-bottom: 25px;
  }

  summary {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 57px;
    padding-right: 145px;
    font-size: 25px;
    line-height: 1.2;
    list-style: none;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer; }
    @media (max-width: 900px) {
      summary {
        font-size: 20px; } }
    @media (max-width: 680px) {
      summary {
        padding-right: 70px;
        font-size: 18px; } }
    summary::-webkit-details-marker {
      display: none; }
    summary::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 57px;
      height: 57px;
      border-style: solid;
      border-width: 1px;
      border-color: rgb(0, 0, 0);}
    summary::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: 0.25s;
      width: 57px;
      height: 57px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg stroke='none' stroke-width='2px' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg transform='translate(1.000000, 1.000000)' stroke='%23000'%3E%3Cpath d='M0,11 L22,11'%3E%3C/path%3E%3Cpath d='M11,0 L11,22'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }
    summary:hover::before {
      box-shadow: 2.121px 2.121px 18px 0px rgba(0, 0, 0, 0.25);  }
   p {
    padding: 20px 0; }
  &[open] summary::after {
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg); }
}
